import {format} from "date-fns";
import {DATETIME_FORMAT} from "../constants";

const timeStamp = () => format(new Date(), DATETIME_FORMAT);

export const emptyCrewHubState = {
  schedule: [],
  flights: [],
  flightsResponse: {
    flights: [],
    responseCode: '',
    errorMessage: '',
    time: timeStamp(),
  },
  scheduleResponse: {
    schedule: [],
    responseCode: '',
    errorMessage: '',
    time: timeStamp(),
  },
  manifests: [],
  manifestsResponse: {
    manifests: [],
    responseCode: '',
    errorMessage: '',
    time: timeStamp(),
  },
  seatMaps: [],
  seatMapsResponse: {
    seatMaps: [],
    responseCode: '',
    errorMessage: '',
    time: timeStamp(),
  },
  time: timeStamp(),
  weightBalance: {
    baggageSummary: {
      bagCount: 0,
      bagWeight: 0,
      weightType: ''
    },
    bookedSegments: [],
    zones: []
  },
  flightSubscriptions: [],
  manifestSubscriptions: [],
  seatMapSubscriptions: [],
  loading: false,
  isOnline: true
};
