import React from 'react';
import App from './app/App';
import { createRoot } from 'react-dom/client';
import { MsalProvider, StorageProvider, CrewHubStoreProvider } from '@hooks';
import { CrewHubTheme } from '@themes';
import { authConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';

const rootDiv =
  document.getElementById('root') || document.createElement('div');
const root = createRoot(rootDiv);

root.render(
  <StorageProvider>
      <BrowserRouter>
        <MsalProvider config={authConfig}>
          <ThemeProvider theme={CrewHubTheme}>
            <CrewHubStoreProvider initializeFromStorage={true}>
              <App />
            </CrewHubStoreProvider>
          </ThemeProvider>
        </MsalProvider>
      </BrowserRouter>
  </StorageProvider>
);
