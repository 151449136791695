// TODO: Define the type https://emotion.sh/docs/typescript#define-a-theme
export const CrewHubTheme = {
  colors: {
    primary: '#001633',
    nice: '#1F74DF',
    nicer: '#0C4D9D',
    nicest: '#002859',
    white: '#FFFFFF',
    lightGrey: '#F4F6F8',
    accentGrey: '#677F9B',
    accentGreyTwo: '#EAEDF4',
    green: '#61C31F',
    lightRed: '#F85C5D',
    lightBlue: '#2B7DE1',
    darkBlue: '#092F5D',
    purple: '#6b34ff'
  },
  fonts: {
    primary: 'Montserrat',
    secondary: 'Poppins',
    openSans: 'Open Sans, sans-serif',
    worksSans: 'Work Sans, sans-serif'
  },
  fontSizes: {
    xLarge: '2rem',
    large: '1.5rem',
    medium: '1rem',
    small: '0.75rem',
    xSmall: '0.5rem'
  },
  borders: {
    smallGrey: '1px solid #677F9B',
    smallWhite: '1px solid #FFFFFF'
  },
  breakpoints: {
    extraLarge: '1200px',
    large: '992px',
    medium: '830px',
    small: '400px'
  },
  padding: {
    leftEdge: '14px'
  }
};
