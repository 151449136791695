import styled from '@emotion/styled';
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import { FlightDropdown } from '../flight-dropdown/flight-dropdown';
import { useCrewHubStore, useCrewHubStoreDispatch, useStorage } from '@hooks';
import React, { useEffect, useState } from 'react';
import LoadingScreenModal from '../loading-screen-modal/loading-screen-modal';
import { isWithinDaysOfLastUpdate } from '@utilities';
import { CREWHUB_ROUTES } from '@models';
import { Network } from '@capacitor/network';
import { ApolloError } from '@apollo/client/errors';
import CrewHubLogo from '@components/lib/crew-hub-logo/crew-hub-logo';
import { CrewHubActionTypes } from '@hooks/lib/crew-hub-store/crew-hub-store-actions';

export interface PageHeaderProps {
  flightNumber?: string;
  showLogo?: boolean;
  showFlightNumber: boolean;
  loading?: boolean;
  itemToLoad?: any;
  loadingMessage?: string;
  useModal?: boolean;
  scheduleError?: ApolloError;
  manifestsError?: ApolloError;
  flightsError?: ApolloError;
  seatMapsError?: ApolloError;
}

const defaultProps: PageHeaderProps = {
  flightNumber: '',
  showLogo: false,
  showFlightNumber: true,
  loading: false,
  useModal: false,
};

const UpdatedTime = styled.p(
  ({theme}) => `
  color: ${theme.colors.white};
  text-align: center;
  font-size: ${theme.fontSizes.small};
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0;
  background: ${theme.colors.primary}
`
);

const LogoContainer = styled.div(
  ({theme}) => `
  text-align: center;
  background: ${theme.colors.primary};
  margin-left: -42px;
`
);

const Logo = () => (
  <LogoContainer>
    <CrewHubLogo pxWidth={'150px'} pxHeight={'18px'}/>
  </LogoContainer>
);

const FlightNumber = styled(IonTitle)(
  ({theme}) => `
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.fontSizes.small};
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
`
);

const BackButton = styled(IonBackButton)(
  `
    margin: 0;
    padding: 30%;
  `
);

const ErrorMessage = styled.small(
  ({theme}) => `
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  margin: 0;
  padding: 8px;
  color: white;
  background-color: ${theme.colors.primary};
  `
)

export function PageHeader({flightNumber, showLogo, showFlightNumber, loading, itemToLoad, loadingMessage, useModal, scheduleError, manifestsError, seatMapsError, flightsError}: PageHeaderProps = defaultProps) {
  const store = useCrewHubStore();
  const dispatch = useCrewHubStoreDispatch();
  const time = store?.time ? store.time : '- No Previous Updates Found';
  const [showModal, setShowModal] = useState<boolean>(false);
  const storage = useStorage();
  const upToDate = isWithinDaysOfLastUpdate(1, store?.time);
  const router = useIonRouter();
  const isOnline = store?.isOnline;

  const addListener = async () => {
      Network.addListener('networkStatusChange', (status) => {
        dispatch({
          type: CrewHubActionTypes.setIsOnline,
          payload: status.connected
        })
      })
  };

  useEffect(() => {
    Network.getStatus().then((response) => {
      dispatch({
        type: CrewHubActionTypes.setIsOnline,
        payload: response.connected
      })
    }).catch((err) => console.log(err));
    addListener().catch((err) => console.log(err));
  }, []);

useEffect(() => {
  let timeoutId: string | number | NodeJS.Timeout | undefined;
  if (showModal) {
    timeoutId = setTimeout(() => {
      if (showModal) {
        console.log('Error: Loading Modal Timeout')
        dispatch({
          type: CrewHubActionTypes.setLoading,
          payload: false
        })
      }
    }, 30000)
  }
  return () => clearTimeout(timeoutId);
}, [showModal])

// show modal when loading, no storage, not up to date or loading item doesnt exist and only when online
useEffect(() => {
  if (loading || !storage || !upToDate) {
    setShowModal(true);
  } else {
    setShowModal(false);
  }

  if (!isOnline) {
    setShowModal(false);
  }
}, [loading, storage, upToDate, itemToLoad, isOnline])

return showModal && loadingMessage && useModal && isOnline ?
  <LoadingScreenModal
    loadingMessage={loadingMessage}
    isOpen={showModal}
    data-testid="modal"
  /> : (
    <IonHeader>
      <IonToolbar color="primary">
        {router.routeInfo.pathname === CREWHUB_ROUTES.SCHEDULE ? (
          <IonButtons slot="start" data-testid="menu-button">
            <IonMenuButton color="light"/>
          </IonButtons>
        ) : (
          <IonButtons slot="start">
            <BackButton color="light" text="" data-testid="back-button"/>
          </IonButtons>
        )}
        {showLogo && <Logo/>}
        {showFlightNumber && <FlightNumber>Flight {flightNumber}</FlightNumber>}
      </IonToolbar>
      <UpdatedTime>Last Updated {time}</UpdatedTime>
      {!store.isOnline && <ErrorMessage>You are OFFLINE</ErrorMessage>}
      {((isOnline && scheduleError?.message.includes('Server response was missing')) || (isOnline && scheduleError?.message.includes('Failed to fetch')))
        && <ErrorMessage>{`Schedule: ${scheduleError?.message}`}</ErrorMessage>
      }
      {((isOnline && manifestsError?.message.includes('Server response was missing')) || (isOnline && manifestsError?.message.includes('Failed to fetch')))
        && <ErrorMessage>{`Manifests: ${manifestsError?.message}`}</ErrorMessage>
      }
      {((isOnline && seatMapsError?.message.includes('Server response was missing')) || (isOnline && seatMapsError?.message.includes('Failed to fetch')))
        && <ErrorMessage>{`Seatmaps: ${seatMapsError?.message}`}</ErrorMessage>
      }
      {((isOnline && flightsError?.message.includes('Server response was missing')) || (isOnline && flightsError?.message.includes('Failed to fetch')))
        && <ErrorMessage>{`Flights: ${flightsError?.message}`}</ErrorMessage>
      }
      {router.routeInfo.pathname !== CREWHUB_ROUTES.SCHEDULE && <FlightDropdown/>}
    </IonHeader>
  );
}

export default PageHeader;
