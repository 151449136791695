import styled from '@emotion/styled';
import { Flight, Manifest, Passenger } from '@models';
import {
  findLeg,
  findManifestForFlight,
  sortPaxBySeat
} from '@utilities';
import { GuestListItem } from '../guest-list-item/guest-list-item';
import { useCrewHubStore } from '@hooks';

export interface GuestListProps {
  flight?: Flight;
}

const BorderTop = styled.div(
  ({ theme }) => `
    border-top: ${theme.borders.smallGrey};
  `
);

const Row = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    font-family: ${theme.fonts.openSans};
    font-weight: bold;
    background-color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.medium};
    color: #f4f6f8;
    padding: 10px 0 10px 0;
  `
);

export function GuestListView({ flight }: GuestListProps) {
  const store = useCrewHubStore();
  const manifest: Manifest | undefined =
    store.manifests &&
    flight &&
    findManifestForFlight(store.manifests, flight);
  const passengerArray = manifest?.passengerList;

  const seatAssignedPax = passengerArray
    ? passengerArray?.filter((pax) => {
        // handle breeze thru
        const leg = findLeg(flight, pax);
        return leg?.unitDesignator !== null;
      })
    : [];

  const seatNotAssignedPax = passengerArray
    ? passengerArray?.filter((pax) => {
        // handle breeze thru
        const leg = findLeg(flight, pax);
        return leg?.unitDesignator === null;
      })
    : [];

  const sortedPassengerArray = seatAssignedPax
    ? sortPaxBySeat(seatAssignedPax, flight)
    : [];
  const fullSortedPax = [...sortedPassengerArray, ...seatNotAssignedPax];
  return (
    <>
      {passengerArray && passengerArray.length === 0 && (
        <Row data-testid="no-passengers">No Guest Boarded</Row>
      )}
      <BorderTop />
      {passengerArray &&
        passengerArray.length !== 0 &&
        fullSortedPax.map((passenger: Passenger) => (
          <GuestListItem passenger={passenger} key={passenger.passengerId} />
        ))}
    </>
  );
}

export default GuestListView;
