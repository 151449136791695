import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel, useIonRouter
} from '@ionic/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import {
  airplaneOutline,
  airplaneSharp,
  gridOutline,
  gridSharp,
  listOutline,
  listSharp,
  medicalOutline,
  medicalSharp,
} from 'ionicons/icons';
import { useTheme } from '@emotion/react';
import { CREWHUB_ROUTES } from '@models';


const AccordionStyles = styled.div(
  ({ theme }) => `
        background: ${theme.colors.primary};
        text-align: center;
        margin: auto;
        display: grid;
        place-items: center;
        border: none;
        font-size: ${theme.fontSizes.medium};
    `
);

const LabelStyles = styled.div(
  ({ theme }) => `
        font-size: ${theme.fontSizes.medium};
        border: none;
    `
);

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const flightPages: AppPage[] = [
  {
    title: 'Flight Details',
    url: CREWHUB_ROUTES.FLIGHT_DETAILS,
    iosIcon: airplaneOutline,
    mdIcon: airplaneSharp
  },
  {
    title: 'Guest List',
    url: CREWHUB_ROUTES.GUEST_LIST,
    iosIcon: listOutline,
    mdIcon: listSharp
  },
  {
    title: 'Seat Map',
    url: CREWHUB_ROUTES.SEATMAP,
    iosIcon: gridOutline,
    mdIcon: gridSharp
  },
  {
    title: 'Special Services',
    url: CREWHUB_ROUTES.SSR_DETAILS,
    iosIcon: medicalOutline,
    mdIcon: medicalSharp
  }
];

export function FlightDropdown() {
  const { flightId } = useParams<{ flightId?: string }>();
  const router = useIonRouter();
  const newPages = flightPages.map((page: AppPage) => {
    if (
      page.title === 'Guest List' ||
      page.title === 'Special Services' ||
      page.title === 'Seat Map'
    ) {
      return {
        title: page.title,
        url: `${page.url}/${flightId}`,
        iosIcon: page.iosIcon,
        mdIcon: page.mdIcon
      };
    } else {
      return {
        title: page.title,
        url: `${page.url}/${flightId}`,
        iosIcon: page.iosIcon,
        mdIcon: page.mdIcon
      };
    }
  });
  const currentPage = newPages.find((page) => page.url === router.routeInfo.pathname);

  const menuItems = newPages.filter((item) => item.url !== router.routeInfo.pathname);

  const { colors } = useTheme();

  return (
          <AccordionStyles>
            <IonAccordionGroup
              className="background-none"
              // value needs to be empty string so dropdown closes after navigating away
              value=""
              data-testid="accordion-group"
            >
              <IonAccordion value="first">
                <IonItem slot="header" className="ion-text-center" color="primary">
                  <IonIcon
                    slot="start"
                    ios={currentPage?.iosIcon}
                    md={currentPage?.mdIcon}
                    style={{ color: colors.lightBlue }}
                  />
                  <LabelStyles>
                    <IonLabel>{currentPage?.title}</IonLabel>
                  </LabelStyles>
                </IonItem>
                {menuItems
                  .filter((Page) => Page.title !== 'Guest Details')
                  .map((flightPage) => {
                    return (
                       <IonItem
                        slot="content"
                        routerLink={flightPage.url}
                        color="primary"
                        className="border-none"
                        lines="none"
                        key={flightPage.url}
                      >
                        <IonIcon
                          slot="start"
                          ios={flightPage.iosIcon}
                          md={flightPage.mdIcon}
                        />
                        <IonLabel>
                          <div>{flightPage.title}</div>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
              </IonAccordion>
            </IonAccordionGroup>
          </AccordionStyles>
  );
}

export default FlightDropdown;
