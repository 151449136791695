import {FlightSubscription, ScheduleFlight, Flight, FlightUpdateResponse} from '@models';
import * as appsyncMethods from '@utilities/src/lib/app-sync-utils/app-sync-utils';
import { addReportTime, createFlightName, userInCrewList } from '@utilities';
import { useEffect, useState } from 'react';
import { flightUpdatesSubscription } from '@graphql/lib/flight-updates-subscription';
import {
  useCrewHubStore,
  useCrewHubStoreDispatch
} from '../crew-hub-store/crew-hub-store';
import {CrewHubActionTypes } from '../crew-hub-store/crew-hub-store-actions';
import { useSession } from '../msal/msal';
import { cloneDeep } from 'lodash';
import { GraphQLSubscription } from '@aws-amplify/api';
import { environment } from 'environments/environment';

export function useFlightSubscriptions() {
  const dispatch = useCrewHubStoreDispatch();
  const [flightSchedule, setFlightSchedule] = useState<ScheduleFlight[]>([]);
  const [token, setToken] = useState<string>('');
  const store = useCrewHubStore();
  const { acquireTokenSilent, username } = useSession();
  const online = store.isOnline;

  acquireTokenSilent([environment.apiScope]).then((accessToken) => {
    setToken(accessToken);
  })

  useEffect(() => {
    if (store && store.schedule && store.schedule.length !== 0 && online) {
      setFlightSchedule(store.schedule);
    }
  }, [store]);

  useEffect(() => {
    if (flightSchedule.length !== 0 && token && online) {
      // clear all existing subscriptions
      if(store?.flightSubscriptions) {
        store?.flightSubscriptions?.forEach((sub) => {
          try {
            sub.subscription.unsubscribe();
          } catch {
            console.log("Failed to unsubscribe");
          }
        });
      }

      dispatch({
        type: CrewHubActionTypes.clearFlightSubscriptions,
        payload: null
      })

      flightSchedule.forEach((flight: ScheduleFlight) => {
        const flightName = createFlightName(flight);
        const subscription: GraphQLSubscription<any> = appsyncMethods.subscribe(
          flightUpdatesSubscription,
          { flightName: flightName },
          (response: FlightUpdateResponse) => {
            const updatedFlight: Flight = response?.flightUpdates as Flight;
            const hasUserInCrew = userInCrewList(response.flightUpdates.crew, username);
            const newUpdate = cloneDeep(updatedFlight);
            newUpdate.reportTime = addReportTime(updatedFlight);

            if (hasUserInCrew) {
              dispatch({
                type: CrewHubActionTypes.updateFlight,
                payload: newUpdate
              });
            } else {
              dispatch({
                type: CrewHubActionTypes.removeFlight,
                payload: newUpdate
              });
            }
          },
          token
        );

        const payload: FlightSubscription = {
          flightName: flightName,
          subscription: subscription
        }

        // store subscription
        dispatch({
          type: CrewHubActionTypes.setFlightSubscriptions,
          payload: payload
        });
      });
    }
  }, [flightSchedule, token, online]);
}
