import { IonContent, IonPage } from '@ionic/react';
import { PageHeader, SeatMapStructure } from '@components';
import { useCrewHubStore } from '@hooks';
import { RouteComponentProps } from 'react-router-dom';
import { Flight } from '@models';
import {
  findSelectedFlightFromState
} from '@utilities';

export interface SeatMapPageProps
  extends RouteComponentProps<{
    flightId: string;
  }> {}

export function SeatMapPage( {match}: SeatMapPageProps ) {
  const state = useCrewHubStore();
  const flight: Flight | undefined =
    state.flights && findSelectedFlightFromState(state.flights, match.params.flightId);

  return (
    <IonPage>
      <PageHeader flightNumber={flight?.flightNumber} showFlightNumber={true} />
      <IonContent fullscreen>
        {flight && <SeatMapStructure flight={flight} />}
      </IonContent>
    </IonPage>
  );
}
