import { SSR } from '../nav-models';

export const MockWCHSSsr: SSR = {
  code: 'WCHS',
  name: 'Wheelchair assistance required; cannot ascend/descend stairs'
};

export const MockWCOBSsr: SSR = {
  code: 'WCOB',
  name: 'Guest request to stow personal wheelchair onboard'
};

export const MockPPOCSsr: SSR = {
  code: 'PPOC',
  name: 'Personal portable oxygen concentrator'
};

export const MockWCBDSsr: SSR = {
  code: 'WCBD',
  name: 'Guest traveling with a dry cell battery-powered wheelchair'
};

export const MockINFTSsr: SSR = {
  code: 'INFT',
  name: 'Lap Infant'
};

export const MockBLNDSsr: SSR = {
  code: 'BLND',
  name: 'Blind'
};

export const MockFAKESsr: SSR = {
  code: 'FAKE',
  name: 'A Fake SSR that we will never have an icon for'
};
