import { useEffect } from 'react';
import { useCrewHubStoreDispatch } from '../crew-hub-store/crew-hub-store';
import { CrewHubActionTypes } from '../crew-hub-store/crew-hub-store-actions';

export function SetTestStore({
  children,
  state,
  action
}: {
  children: any;
  state: any;
  action: CrewHubActionTypes;
}) {
  const dispatch = useCrewHubStoreDispatch();
  useEffect(() => {
    dispatch({ type: action, payload: state });
  }, [dispatch]);
  return children;
}
