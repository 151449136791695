import { gql } from '@apollo/client';

export const manifestUpdatesSubscription = gql`
  subscription manifestUpdates($manifestName: String!) {
    manifestUpdates(manifestName: $manifestName) {
      manifestName
      manifest {
        checkInLid
        market {
          departureDate
          destination
          identifier {
            identifier
          }
          origin
        }
        passengerCounts {
          checkedIn
          clearedStandBy
          clearedThruStandby
          manifested
          noShow
          standBy
          thruCheckedIn
          thruManifested
          thruNoShow
          thruStandBy
          totalCheckedIn
          totalOnBoard
        }
        passengerList {
          passengerId
          recordLocator
          name {
            first
            last
            title
          }
          infant {
            gender
            name {
              first
              last
            }
          }
          services {
            segments {
              isBreezeThru
              seatChangeRequired
              segmentKey
              market {
                departureDate
                destination
                origin
                identifier {
                  identifier
                }
              }
              legs {
                unitDesignator
                ssrs {
                  code
                  name
                }
                market {
                  departureDate
                  destination
                  origin
                  identifier {
                    identifier
                  }
                }
              }
            }
          }
          passengerInformation {
            dateOfBirth
          }
        }
      }
    }
  }
`;
