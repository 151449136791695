import { ScheduleFlight } from '@models';
import { extractFlightNum } from '../flight-utils/flight-utils';

export function createFlightName(flight: ScheduleFlight) {
  return `schedule_${baseChannelName(flight)}`;
}

export function createManifestName(flight: ScheduleFlight) {
  return `manifest_${baseChannelName(flight)}`;
}

export function createSeatMapName(flight: ScheduleFlight) {
  return `seat_map_${baseChannelName(flight)}`;
}

function baseChannelName(flight: ScheduleFlight) {
  return `${extractFlightNum(flight.activityCode)}_${flight.start?.split('+')[0]}_${flight.startAirportCode}_${flight.endAirportCode}`;
}
