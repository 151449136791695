import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IonButton, IonCol, IonGrid, IonPage, IonRow } from '@ionic/react';
import logo from '../../../../assets/src/lib/crew-hub-logo.svg';
import CrewHubLogo from "@components/lib/crew-hub-logo/crew-hub-logo";

export interface LoginProps {
  onClick: () => void;
}

export function Login(props: LoginProps) {
  const theme: any = useTheme();
  const { fonts, colors } = theme;

  const LoginPage = styled.div`
    background-color: ${colors.primary};
    height: 100%;
    align-items: center;
  `;

  const Grid = styled(IonGrid)`
    height: 100%;
    display: flex;
    flex-flow: column;
  `;

  const Row = styled(IonRow)`
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0;
  `;

  const SpacerRow = styled(Row)`
    flex-grow: 1;
  `;

  const LoginButton = styled(IonButton)`
    bottom: 30px;
    margin: 0 20px 20px 20px;
    font-family: ${fonts.primary};
  `;

  return (
    <LoginPage>
      <Grid>
        <SpacerRow />
        <Row>
          <IonCol>
            <CrewHubLogo />
          </IonCol>
        </Row>
        <SpacerRow />
        <Row>
          <IonCol>
            <LoginButton expand="block" onClick={props.onClick}>
              Sign In
            </LoginButton>
          </IonCol>
        </Row>
      </Grid>
    </LoginPage>
  );
}

export default Login;
