import { API, graphqlOperation } from '@aws-amplify/api';

/**
 * @param  {string} name the name of the channel
 * @param  {nextCallback} next callback function that will be called with subscription payload data
 * @param  {function} [error] optional function to handle errors
 * @returns {Observable} an observable subscription object
 */
export function subscribe(
  query: any,
  variables: any,
  next: any,
  token: any,
  error?: any
) {
  return API.graphql(
    graphqlOperation(query, variables, `Bearer ${token}`)
  ).subscribe({
    next: ({ value }: any) => {
      next(value.data);
    },
    error: error || console.log
  });
}

/**
 * @callback nextCallback
 * @param {Object} data the subscription response including the `name`, and `data`.
 * @param {Object} [provider] the provider object
 * @param {Object} [payload] the entire payload
 */
