import {ErrorFallback, PageHeader, ScheduleListView} from '@components';
import styled from '@emotion/styled';
import {
  useManifest,
  useSeatMap,
  useManifestSubscription,
  useSeatMapSubscription,
  useFlightSubscriptions,
  useCrewHubStore,
  useCrewSchedule,
  useFlightsForCrew,
  useCrewHubStoreDispatch
} from '@hooks';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail
} from '@ionic/react';
import {ErrorBoundary} from 'react-error-boundary';
import {useEffect} from 'react';
import {CrewHubActionTypes} from "@hooks/lib/crew-hub-store/crew-hub-store-actions";
import { environment } from 'environments/environment';

const StyledPage = styled(IonPage)(
  ({theme}) => `
    background: ${theme.colors.primary}
  `
);

// TODO: Figure out correct type for ionicStorage
export function SchedulePage() {
  const store = useCrewHubStore();
  const dispatch = useCrewHubStoreDispatch();
  const navitaireBaseUrl = environment.navitaireUrl
  const apiScope = environment.apiScope;
  const {loading: scheduleLoading, error: scheduleError, refetch} = useCrewSchedule(1800000, apiScope);
  const {loading: flightsLoading, error: flightsError} =  useFlightsForCrew();
  const {loading: manifestLoading, error: manifestError} = useManifest(navitaireBaseUrl, apiScope);
  const {loading: seatMapLoading, error: seatMapError} = useSeatMap(navitaireBaseUrl, apiScope);

  useFlightSubscriptions();
  useManifestSubscription();
  useSeatMapSubscription();

  const flights = store?.flights;
  const schedule = store?.schedule;
  const manifests = store?.manifests;
  const seatMaps = store?.seatMaps;
  const stillLoading = store?.loading;
  const online = store?.isOnline;

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    if (online) {
      refetch();
      dispatch({
        type: CrewHubActionTypes.setLoading,
        payload: true
      })
    }
    event.detail.complete();
  }

  useEffect(() => {
    if(
        flights &&
        manifests &&
        seatMaps &&
        store &&
        flights.length > 0 && 
        manifests.length > 0 && 
        seatMaps.length > 0 && 
        store.loading
      ){
      dispatch({
        type: CrewHubActionTypes.setLoading,
        payload: false
      })
    }
  }, [flights])

  return (
    <StyledPage>
      <PageHeader
        showFlightNumber={false}
        showLogo={true}
        loading={stillLoading}
        itemToLoad={flights}
        loadingMessage={'Your Schedule'}
        useModal={true}
        scheduleError={scheduleError}
        flightsError={flightsError}
        manifestsError={manifestError}
        seatMapsError={seatMapError}
      />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ScheduleListView
            flights={flights}
            loading={stillLoading}
            error={undefined}
          />
        </ErrorBoundary>
      </IonContent>
    </StyledPage>
  );
}
