import {
  Flight,
  Manifest,
  ScheduleFlight,
  SeatMap,
  SeatMapColumnLabelRow,
  SeatMapProperty,
  UnitInformation,
  UnitType
} from '@models';
import { compareFlightData, compareScheduledFlightData } from '../flight-utils/flight-utils';

export function getColumnLabels(
  sortedColumnLables: SeatMapColumnLabelRow[]
): SeatMapColumnLabelRow[] {
  let lastProductClass: string;
  return sortedColumnLables.filter((label) => {
    if (label.productClass !== lastProductClass) {
      lastProductClass = label.productClass;
      return true;
    }
    return false;
  });
}

export function getProductClass(seat: UnitInformation): string {
  if (seat.properties?.find((prop) => prop.code === 'BUSINESS')) {
    return 'nicest';
  } else if (seat.properties?.find((prop) => prop.code === 'PREMIUM')) {
    return 'nicer';
  }
  return 'nice';
}

export function buildColumnLabels(
  seat: UnitInformation,
  columnLabels: SeatMapColumnLabelRow[]
) {
  if (!seat.designator || seat.type !== UnitType.NormalSeat) {
    return;
  }
  const row = seat.y - 1;
  const column = seat.x;
  const rowLabels = columnLabels.find(
    (label: SeatMapColumnLabelRow) => label.row === row
  );
  const letter = seat.designator[seat.designator.length - 1];
  if (!rowLabels) {
    columnLabels.push({
      labels: [{ letter, column }],
      row,
      productClass: getProductClass(seat)
    });
  } else if (!rowLabels.labels.find((label) => label.letter === letter)) {
    rowLabels.labels.push({ letter, column });
  }
}

export function setUpSeatMap(seats: UnitInformation[]) {
  let columns = 0;
  let minY = 10000; // number guaranteed to be bigger than max number of rows on the seat map
  let maxY = 0;
  const middleExitRows: number[] = [];
  const columnLabels: SeatMapColumnLabelRow[] = [];

  seats.forEach((seat: UnitInformation) => {
    columns = seat.x > columns ? seat.x : columns;
    minY = seat.unitKey !== null && seat.y < minY ? seat.y : minY;
    maxY = seat.unitKey !== null && seat.y > maxY ? seat.y : maxY;
    buildColumnLabels(seat, columnLabels);
    if (
      seat.properties?.find(
        (property: SeatMapProperty) => property.code === 'EXITROW'
      )
    ) {
      middleExitRows.push(seat.y);
    }
  });

  const sortedColumnLabels = columnLabels.sort((a, b) => a.row - b.row);
  const columnLabelSections = getColumnLabels(sortedColumnLabels);
  const allExitRows = [
    minY,
    ...middleExitRows
      .filter((row, index) => middleExitRows.indexOf(row) === index)
      .sort(),
    maxY
  ];

  return { columns, minY, maxY, allExitRows, middleExitRows, columnLabelSections };
}

export function findManifestForFlight(
  manifests: Manifest[],
  flight: Flight
): Manifest | undefined {
  return manifests.find((manifest: Manifest) => {
    return compareFlightData(flight, manifest.market);
  });
}

export function findSeatMapForFlight(
  seatmaps: SeatMap[],
  flight: Flight
): SeatMap | undefined {
  return seatmaps.find((seatMap: SeatMap) => {
    // FIXME
    return compareFlightData(flight, seatMap?.market);
  });
}

export const addMissingFirstRowLabels = (columnLabels: SeatMapColumnLabelRow[]): SeatMapColumnLabelRow[] => {
  let missingRowIndex = -1
  columnLabels.forEach((labelRow, index) => {
    if((labelRow.row === 1 && !labelRow.labels.includes({letter: 'A', column: 1})) ||
      (labelRow.row === 1 && !labelRow.labels.includes({letter: 'C', column: 3}))
    ){
      missingRowIndex = index;
    }
  });

  if(missingRowIndex !== -1) {
    const labels = columnLabels[missingRowIndex].labels;
    const row = columnLabels[missingRowIndex].row;
    const productClass = columnLabels[missingRowIndex].productClass;
    labels.push(
      {letter: 'A', column: 1}
    );
    labels.push(
      {letter: 'C', column: 3}
    );
    const newColumnLabels = columnLabels.filter((item, index) => {
      return item.row !== row
    })

    newColumnLabels.unshift(
      {
        labels,
        row,
        productClass
      }
    )
    missingRowIndex = -1;
    return newColumnLabels;
  }
  return columnLabels;
}

export const removeExtraSectionLabels = (columnLabels: SeatMapColumnLabelRow[], middleExitRow: number, flightType: string): SeatMapColumnLabelRow[] => {
  if(flightType === "A220"){
    return columnLabels.filter((labelRow) => labelRow.row <= (middleExitRow-1));
  }
  else {
    return columnLabels.filter((labelRow) => {
      return labelRow.row === (middleExitRow-1) || labelRow.row === 1
    })
  }
}
