import { UnitInformation } from '../nav-models';
import { UnitType } from '../constants';
import {
  MockPassenger1A,
  MockPassenger1ABreezeThruNoSeatChange,
  MockPassenger1ABreezeThruSeatChange
} from '../mock-data/mock-passenger-data';
import { cloneDeep } from '@apollo/client/utilities';

const units: UnitInformation[] = [
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMUEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '1A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 1,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 7,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMUMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '1C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 1,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 7,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '1D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 1,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 7,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMUYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '1F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 1,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 7,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMkEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '2A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 2,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 10,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMkMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '2C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 2,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 10,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMkQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '2D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 2,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 10,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMkYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '2F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 2,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 10,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhM0EhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '3A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 3,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 13,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhM0MhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '3C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 3,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 13,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhM0QhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '3D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 3,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 13,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhM0YhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '3F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 3,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 13,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNEEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '4A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 4,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 16,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNEMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '4C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 4,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 16,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNEQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '4D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 4,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 16,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNEYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '4F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 4,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 16,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNUEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '5A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 5,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 19,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNUMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '5C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 5,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 19,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNUQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '5D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 5,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 19,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNUYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '5F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 5,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 19,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNkEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '6A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 6,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 22,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNkMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '6C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 6,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 22,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNkQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '6D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 6,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 22,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhNkYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '6F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 6,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 22,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhN0EhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '7A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 7,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 25,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhN0MhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '7C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 7,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 25,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhN0QhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '7D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 7,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 25,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhN0YhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '7F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 7,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 25,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOEEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '8A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 8,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 28,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOEMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '8C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 8,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 28,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOEQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '8D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 8,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 28,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOEYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '8F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 8,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 28,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOUEhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '9A',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 9,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 31,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOUMhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '9C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 9,
    text: null,
    type: UnitType.NormalSeat,
    x: 4,
    y: 31,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOUQhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '9D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 9,
    text: null,
    type: UnitType.NormalSeat,
    x: 8,
    y: 31,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhOUYhWQ--',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '9F',
    properties: [
      {
        code: 'BUSINESS',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 9,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 31,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTBBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '10A',
    properties: [
      {
        code: 'EXITROW',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 10,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 36,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTBDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '10C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'EXITROW',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 10,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 36,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTBEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '10D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'EXITROW',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 10,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 36,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTBFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '10E',
    properties: [
      {
        code: 'EXITROW',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 10,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 36,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTBGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '10F',
    properties: [
      {
        code: 'EXITROW',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '4'
      }
    ],
    group: 10,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 36,
    zone: 1
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTFBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '11A',
    properties: [
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 11,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 38,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTFDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '11C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 11,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 38,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTFEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '11D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 11,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 38,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTFFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '11E',
    properties: [
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 11,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 38,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTFGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '11F',
    properties: [
      {
        code: 'LEGROOM',
        value: 'True'
      },
      {
        code: 'PREMIUM',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 11,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 38,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTJBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '12A',
    properties: [
      {
        code: 'DISABIL',
        value: 'True'
      },
      {
        code: 'RESTRICT',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 12,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 40,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTJDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '12C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'DISABIL',
        value: 'True'
      },
      {
        code: 'RESTRICT',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 12,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 40,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTJEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '12D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 12,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 40,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTJFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '12E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 12,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 40,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTJGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '12F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 12,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 40,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTNBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '13A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 13,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 42,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTNDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '13C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 13,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 42,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTNEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '13D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 13,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 42,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTNFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '13E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 13,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 42,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTNGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '13F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 13,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 42,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTRBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '14A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 14,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 44,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTRDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '14C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 14,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 44,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTREIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '14D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 14,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 44,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTRFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '14E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 14,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 44,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTRGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '14F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 14,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 44,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTVBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '15A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 15,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 46,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTVDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '15C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 15,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 46,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTVEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '15D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 15,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 46,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTVFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '15E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 15,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 46,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTVGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '15F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 15,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 46,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTZBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '16A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 16,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 48,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTZDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '16C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 16,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 48,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTZEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '16D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 16,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 48,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTZFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '16E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 16,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 48,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTZGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Unknown',
    designator: '16F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 16,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 48,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTdBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '17A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 17,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 50,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTdDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '17C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 17,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 50,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTdEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '17D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 17,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 50,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTdFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '17E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 17,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 50,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTdGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '17F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 17,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 50,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMThBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '18A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 18,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 52,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMThDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '18C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 18,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 52,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMThEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '18D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 18,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 52,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMThFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '18E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 18,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 52,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMThGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '18F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 18,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 52,
    zone: 2
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTlBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '19A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 19,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 54,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTlDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '19C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 19,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 54,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTlEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '19D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 19,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 54,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTlFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '19E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 19,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 54,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMTlGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '19F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 19,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 54,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjBBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '20A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 20,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 56,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjBDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '20C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 20,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 56,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjBEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '20D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 20,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 56,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjBFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '20E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 20,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 56,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjBGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '20F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 20,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 56,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjFBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '21A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 21,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 58,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjFDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '21C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 21,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 58,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjFEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '21D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 21,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 58,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjFFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '21E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 21,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 58,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjFGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '21F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 21,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 58,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjJBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '22A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 22,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 60,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjJDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '22C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 22,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 60,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjJEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '22D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 22,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 60,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjJFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '22E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 22,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 60,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjJGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '22F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 22,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 60,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjNBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '23A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 23,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 62,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjNDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '23C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 23,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 62,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjNEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '23D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 23,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 62,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjNFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '23E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 23,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 62,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjNGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '23F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 23,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 62,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjRBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '24A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 24,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 64,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjRDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '24C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 24,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 64,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjREIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '24D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 24,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 64,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjRFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '24E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 24,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 64,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjRGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '24F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 24,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 64,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjVBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '25A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 25,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 66,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjVDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '25C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 25,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 66,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjVEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '25D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 25,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 66,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjVFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '25E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 25,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 66,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjVGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '25F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 25,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 66,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjZBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '26A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 26,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 68,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjZDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '26C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 26,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 68,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjZEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '26D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 26,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 68,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjZFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '26E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 26,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 68,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjZGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '26F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 26,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 68,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjdBIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '27A',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 27,
    text: null,
    type: UnitType.NormalSeat,
    x: 1,
    y: 70,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjdDIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '27C',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 27,
    text: null,
    type: UnitType.NormalSeat,
    x: 3,
    y: 70,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjdEIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '27D',
    properties: [
      {
        code: 'AISLE',
        value: 'True'
      },
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 27,
    text: null,
    type: UnitType.NormalSeat,
    x: 7,
    y: 70,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjdFIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '27E',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 27,
    text: null,
    type: UnitType.NormalSeat,
    x: 9,
    y: 70,
    zone: 3
  },
  {
    unitKey: 'TVghMTAwISAhNjM3OTUxOTMyMDAwMDAwMDAwIVRQQSFDSFMhMjdGIVk-',
    allowedSsrs: [],
    assignable: true,
    availability: 'Open',
    designator: '27F',
    properties: [
      {
        code: 'TCC',
        value: 'Y'
      },
      {
        code: 'WINDOW',
        value: 'True'
      },
      {
        code: 'BRDZONE',
        value: '3'
      }
    ],
    group: 27,
    text: null,
    type: UnitType.NormalSeat,
    x: 11,
    y: 70,
    zone: 3
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$0',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Bulkhead,
    x: 1,
    y: 74,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$1',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Bulkhead,
    x: 7,
    y: 74,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$2',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Bulkhead,
    x: 1,
    y: 5,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$3',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Bulkhead,
    x: 8,
    y: 5,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$4',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 0,
    y: 75,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$5',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 0,
    y: 34,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$6',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 0,
    y: 3,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$7',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 13,
    y: 3,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$8',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 13,
    y: 34,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$9',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Exit,
    x: 13,
    y: 75,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$10',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Lavatory,
    x: 1,
    y: 1,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$11',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Galley,
    x: 9,
    y: 1,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$12',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Wing,
    x: 0,
    y: 26,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$13',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Wing,
    x: 13,
    y: 26,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$14',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Lavatory,
    x: 1,
    y: 77,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$15',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Lavatory,
    x: 4,
    y: 77,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$16',
    properties: [],
    group: 0,
    text: null,
    type: UnitType.Galley,
    x: 7,
    y: 77,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$50',
    properties: [
      {
        code: 'LR',
        value: '-66'
      }
    ],
    group: 0,
    text: 'A',
    type: UnitType.LabelRuler,
    x: 1,
    y: -2,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$51',
    properties: [
      {
        code: 'LR',
        value: '1'
      }
    ],
    group: 0,
    text: '1',
    type: UnitType.LabelRuler,
    x: 6,
    y: 7,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$52',
    properties: [
      {
        code: 'LR',
        value: '-68'
      }
    ],
    group: 0,
    text: 'C',
    type: UnitType.LabelRuler,
    x: 4,
    y: -2,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$53',
    properties: [
      {
        code: 'LR',
        value: '-69'
      }
    ],
    group: 0,
    text: 'D',
    type: UnitType.LabelRuler,
    x: 7,
    y: -2,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$54',
    properties: [
      {
        code: 'LR',
        value: '-71'
      }
    ],
    group: 0,
    text: 'F',
    type: UnitType.LabelRuler,
    x: 11,
    y: -2,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$55',
    properties: [
      {
        code: 'LR',
        value: '2'
      }
    ],
    group: 0,
    text: '2',
    type: UnitType.LabelRuler,
    x: 6,
    y: 10,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$56',
    properties: [
      {
        code: 'LR',
        value: '3'
      }
    ],
    group: 0,
    text: '3',
    type: UnitType.LabelRuler,
    x: 6,
    y: 13,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$57',
    properties: [
      {
        code: 'LR',
        value: '4'
      }
    ],
    group: 0,
    text: '4',
    type: UnitType.LabelRuler,
    x: 6,
    y: 16,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$58',
    properties: [
      {
        code: 'LR',
        value: '5'
      }
    ],
    group: 0,
    text: '5',
    type: UnitType.LabelRuler,
    x: 6,
    y: 19,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$59',
    properties: [
      {
        code: 'LR',
        value: '6'
      }
    ],
    group: 0,
    text: '6',
    type: UnitType.LabelRuler,
    x: 6,
    y: 22,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$60',
    properties: [
      {
        code: 'LR',
        value: '7'
      }
    ],
    group: 0,
    text: '7',
    type: UnitType.LabelRuler,
    x: 6,
    y: 25,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$61',
    properties: [
      {
        code: 'LR',
        value: '8'
      }
    ],
    group: 0,
    text: '8',
    type: UnitType.LabelRuler,
    x: 6,
    y: 28,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$62',
    properties: [
      {
        code: 'LR',
        value: '9'
      }
    ],
    group: 0,
    text: '9',
    type: UnitType.LabelRuler,
    x: 6,
    y: 31,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$63',
    properties: [
      {
        code: 'LR',
        value: '10'
      }
    ],
    group: 0,
    text: '10',
    type: UnitType.LabelRuler,
    x: 5,
    y: 36,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$64',
    properties: [
      {
        code: 'LR',
        value: '-70'
      }
    ],
    group: 0,
    text: 'E',
    type: UnitType.LabelRuler,
    x: 9,
    y: -2,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$65',
    properties: [
      {
        code: 'LR',
        value: '11'
      }
    ],
    group: 0,
    text: '11',
    type: UnitType.LabelRuler,
    x: 5,
    y: 38,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$66',
    properties: [
      {
        code: 'LR',
        value: '12'
      }
    ],
    group: 0,
    text: '12',
    type: UnitType.LabelRuler,
    x: 5,
    y: 40,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$67',
    properties: [
      {
        code: 'LR',
        value: '13'
      }
    ],
    group: 0,
    text: '13',
    type: UnitType.LabelRuler,
    x: 5,
    y: 42,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$68',
    properties: [
      {
        code: 'LR',
        value: '14'
      }
    ],
    group: 0,
    text: '14',
    type: UnitType.LabelRuler,
    x: 5,
    y: 44,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$69',
    properties: [
      {
        code: 'LR',
        value: '15'
      }
    ],
    group: 0,
    text: '15',
    type: UnitType.LabelRuler,
    x: 5,
    y: 46,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$70',
    properties: [
      {
        code: 'LR',
        value: '16'
      }
    ],
    group: 0,
    text: '16',
    type: UnitType.LabelRuler,
    x: 5,
    y: 48,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$71',
    properties: [
      {
        code: 'LR',
        value: '17'
      }
    ],
    group: 0,
    text: '17',
    type: UnitType.LabelRuler,
    x: 5,
    y: 50,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$72',
    properties: [
      {
        code: 'LR',
        value: '18'
      }
    ],
    group: 0,
    text: '18',
    type: UnitType.LabelRuler,
    x: 5,
    y: 52,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$73',
    properties: [
      {
        code: 'LR',
        value: '19'
      }
    ],
    group: 0,
    text: '19',
    type: UnitType.LabelRuler,
    x: 5,
    y: 54,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$74',
    properties: [
      {
        code: 'LR',
        value: '20'
      }
    ],
    group: 0,
    text: '20',
    type: UnitType.LabelRuler,
    x: 5,
    y: 56,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$75',
    properties: [
      {
        code: 'LR',
        value: '21'
      }
    ],
    group: 0,
    text: '21',
    type: UnitType.LabelRuler,
    x: 5,
    y: 58,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$76',
    properties: [
      {
        code: 'LR',
        value: '22'
      }
    ],
    group: 0,
    text: '22',
    type: UnitType.LabelRuler,
    x: 5,
    y: 60,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$77',
    properties: [
      {
        code: 'LR',
        value: '23'
      }
    ],
    group: 0,
    text: '23',
    type: UnitType.LabelRuler,
    x: 5,
    y: 62,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$78',
    properties: [
      {
        code: 'LR',
        value: '24'
      }
    ],
    group: 0,
    text: '24',
    type: UnitType.LabelRuler,
    x: 5,
    y: 64,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$79',
    properties: [
      {
        code: 'LR',
        value: '25'
      }
    ],
    group: 0,
    text: '25',
    type: UnitType.LabelRuler,
    x: 5,
    y: 66,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$80',
    properties: [
      {
        code: 'LR',
        value: '26'
      }
    ],
    group: 0,
    text: '26',
    type: UnitType.LabelRuler,
    x: 5,
    y: 68,
    zone: 0
  },
  {
    unitKey: null,
    allowedSsrs: [],
    assignable: false,
    availability: 'Unknown',
    designator: '$81',
    properties: [
      {
        code: 'LR',
        value: '27'
      }
    ],
    group: 0,
    text: '27',
    type: UnitType.LabelRuler,
    x: 5,
    y: 70,
    zone: 0
  }
];

export const MockA220SeatData = {
  data: {
    seatMap: {
      seatMap: {
        data: {
          seatMap: {
            seatMap: {
              seatmapReference:
                'TVghIDEwMCEgITYzNzk1MTkzMjAwMDAwMDAwMCFUUEEhQ0hT',
              arrivalStation: 'CHS',
              departureStation: 'TPA',
              decks: [
                {
                  key: 1,
                  value: {
                    compartments: [
                      {
                        key: 'Y',
                        value: {
                          units
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        }
      }
    }
  }
};

export const MockE195SeatData = {
  data: {
    seatMap: {
      seatMap: {
        data: {
          seatMap: {
            seatMap: {
              seatmapReference:
                'TVghIDI1MyEgITYzNzk2NTA5OTAwMDAwMDAwMCFUUEEhQ0hT',
              arrivalStation: 'CHS',
              departureStation: 'TPA',
              decks: [
                {
                  key: 1,
                  value: {
                    compartments: [
                      {
                        key: 'Y',
                        value: {
                          units: [
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '1D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 1,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 2,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '1F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 1,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 2,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '2A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 2,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 4,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '2C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 2,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 4,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '2D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 2,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 4,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '2F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 2,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 4,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhM0EhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '3A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 3,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 6,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhM0MhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '3C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 3,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 6,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhM0QhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '3D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 3,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 6,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhM0YhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '3F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 3,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 6,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNEEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '4A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 4,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 8,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNEMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '4C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 4,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 8,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNEQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '4D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 4,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 8,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNEYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '4F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 4,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 8,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNUEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '5A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 5,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 10,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNUMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '5C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 5,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 10,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNUQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '5D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 5,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 10,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNUYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '5F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 5,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 10,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNkEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Reserved',
                              designator: '6A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 6,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 12,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNkMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '6C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 6,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 12,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNkQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '6D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 6,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 12,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhNkYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '6F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 6,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 12,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhN0EhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '7A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 7,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 14,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhN0MhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '7C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 7,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 14,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhN0QhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '7D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 7,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 14,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhN0YhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '7F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 7,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 14,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOEEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '8A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 8,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 16,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOEMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '8C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 8,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 16,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOEQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '8D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 8,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 16,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOEYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '8F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 8,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 16,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOUEhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '9A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 9,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 18,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOUMhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '9C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 9,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 18,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOUQhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '9D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 9,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 18,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhOUYhWQ--',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '9F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 9,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 18,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTBBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '10A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 10,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 20,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTBDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '10C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 10,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 20,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTBEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '10D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 10,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 20,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTBGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '10F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 10,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 20,
                              zone: 1
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTFBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '11A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 11,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 22,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTFDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '11C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 11,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 22,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTFEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '11D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 11,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 22,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTFGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '11F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 11,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 22,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTJBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '12A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 12,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 24,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTJDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '12C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 12,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 24,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTJEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '12D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 12,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 24,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTJGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '12F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 12,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 24,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTNBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '13A',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 13,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 26,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTNDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '13C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 13,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 26,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTNEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '13D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 13,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 26,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTNGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '13F',
                              properties: [
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 13,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 26,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTRBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '14A',
                              properties: [
                                {
                                  code: 'EXITROW',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 14,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 28,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTRDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '14C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'EXITROW',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 14,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 28,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTREIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '14D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'EXITROW',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 14,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 28,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTRGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '14F',
                              properties: [
                                {
                                  code: 'EXITROW',
                                  value: 'True'
                                },
                                {
                                  code: 'LEGROOM',
                                  value: 'True'
                                },
                                {
                                  code: 'PREMIUM',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '4'
                                }
                              ],
                              group: 14,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 28,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTVBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '15A',
                              properties: [
                                {
                                  code: 'DISABIL',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'RESTRICT',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 15,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 30,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTVDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '15C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'DISABIL',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'RESTRICT',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 15,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 30,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTVEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '15D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 15,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 30,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTVGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '15F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 15,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 30,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTZBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '16A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 16,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 32,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTZDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '16C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 16,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 32,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTZEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '16D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 16,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 32,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTZGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '16F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 16,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 32,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTdBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '17A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 17,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 34,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTdDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '17C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 17,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 34,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTdEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '17D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 17,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 34,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTdGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '17F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 17,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 34,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMThBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '18A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 18,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 36,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMThDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '18C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 18,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 36,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMThEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '18D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 18,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 36,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMThGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '18F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'WING',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 18,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 36,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTlBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '19A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 19,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 38,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTlDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '19C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 19,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 38,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTlEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '19D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 19,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 38,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMTlGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '19F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 19,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 38,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjBBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '20A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 20,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 40,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjBDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '20C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 20,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 40,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjBEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '20D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 20,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 40,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjBGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '20F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 20,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 40,
                              zone: 2
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjFBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '21A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 21,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 42,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjFDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '21C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 21,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 42,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjFEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '21D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 21,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 42,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjFGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '21F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 21,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 42,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjJBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '22A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 22,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 44,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjJDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '22C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 22,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 44,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjJEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '22D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 22,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 44,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjJGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '22F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 22,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 44,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjNBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '23A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 23,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 46,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjNDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '23C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 23,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 46,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjNEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '23D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 23,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 46,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjNGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '23F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 23,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 46,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjRBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '24A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 24,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 48,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjRDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '24C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 24,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 48,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjREIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '24D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 24,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 48,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjRGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '24F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 24,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 48,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjVBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '25A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 25,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 50,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjVDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '25C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 25,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 50,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjVEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '25D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 25,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 50,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjVGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '25F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 25,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 50,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjZBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '26A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 26,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 52,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjZDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '26C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 26,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 52,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjZEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '26D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 26,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 52,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjZGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '26F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 26,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 52,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjdBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '27A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 27,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 54,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjdDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '27C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 27,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 54,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjdEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '27D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 27,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 54,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjdGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '27F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 27,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 54,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjhBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '28A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 28,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 56,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjhDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '28C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 28,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 56,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjhEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '28D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 28,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 56,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjhGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '28F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 28,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 56,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjlBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '29A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 29,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 58,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjlDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '29C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 29,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 58,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjlEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '29D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 29,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 58,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMjlGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '29F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 29,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 58,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMzBBIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '30A',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 30,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 1,
                              y: 60,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMzBDIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '30C',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 30,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 3,
                              y: 60,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMzBEIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '30D',
                              properties: [
                                {
                                  code: 'AISLE',
                                  value: 'True'
                                },
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'RESTRICT',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 30,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 7,
                              y: 60,
                              zone: 3
                            },
                            {
                              unitKey:
                                'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMzBGIVk-',
                              allowedSsrs: [],
                              assignable: true,
                              availability: 'Open',
                              designator: '30F',
                              properties: [
                                {
                                  code: 'ECONOMY',
                                  value: 'True'
                                },
                                {
                                  code: 'RESTRICT',
                                  value: 'True'
                                },
                                {
                                  code: 'TCC',
                                  value: 'Y'
                                },
                                {
                                  code: 'WINDOW',
                                  value: 'True'
                                },
                                {
                                  code: 'BRDZONE',
                                  value: '3'
                                }
                              ],
                              group: 30,
                              text: null,
                              type: UnitType.NormalSeat,
                              x: 9,
                              y: 60,
                              zone: 3
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$0',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '-69'
                                }
                              ],
                              group: 0,
                              text: 'D',
                              type: UnitType.LabelRuler,
                              x: 7,
                              y: -2,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$1',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '1'
                                }
                              ],
                              group: 0,
                              text: '1',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 2,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$2',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '-71'
                                }
                              ],
                              group: 0,
                              text: 'F',
                              type: UnitType.LabelRuler,
                              x: 9,
                              y: -2,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$3',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 0,
                              y: 1,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$4',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 11,
                              y: 1,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$5',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Bulkhead,
                              x: 1,
                              y: 3,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$6',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Bulkhead,
                              x: 7,
                              y: 1,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$7',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 0,
                              y: 28,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$8',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 11,
                              y: 28,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$9',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Wing,
                              x: 0,
                              y: 23,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$10',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Wing,
                              x: 11,
                              y: 23,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$11',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 11,
                              y: 65,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$12',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Exit,
                              x: 0,
                              y: 65,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$13',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Bulkhead,
                              x: 1,
                              y: 64,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$14',
                              properties: [],
                              group: 0,
                              text: null,
                              type: UnitType.Bulkhead,
                              x: 7,
                              y: 64,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$15',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '-66'
                                }
                              ],
                              group: 0,
                              text: 'A',
                              type: UnitType.LabelRuler,
                              x: 1,
                              y: -2,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$16',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '2'
                                }
                              ],
                              group: 0,
                              text: '2',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 4,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$17',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '-68'
                                }
                              ],
                              group: 0,
                              text: 'C',
                              type: UnitType.LabelRuler,
                              x: 3,
                              y: -2,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$18',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '3'
                                }
                              ],
                              group: 0,
                              text: '3',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 6,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$19',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '4'
                                }
                              ],
                              group: 0,
                              text: '4',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 8,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$20',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '5'
                                }
                              ],
                              group: 0,
                              text: '5',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 10,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$21',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '6'
                                }
                              ],
                              group: 0,
                              text: '6',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 12,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$22',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '7'
                                }
                              ],
                              group: 0,
                              text: '7',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 14,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$23',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '8'
                                }
                              ],
                              group: 0,
                              text: '8',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 16,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$24',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '9'
                                }
                              ],
                              group: 0,
                              text: '9',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 18,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$25',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '10'
                                }
                              ],
                              group: 0,
                              text: '10',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 20,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$26',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '11'
                                }
                              ],
                              group: 0,
                              text: '11',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 22,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$27',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '12'
                                }
                              ],
                              group: 0,
                              text: '12',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 24,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$28',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '13'
                                }
                              ],
                              group: 0,
                              text: '13',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 26,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$29',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '14'
                                }
                              ],
                              group: 0,
                              text: '14',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 28,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$30',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '15'
                                }
                              ],
                              group: 0,
                              text: '15',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 30,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$31',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '16'
                                }
                              ],
                              group: 0,
                              text: '16',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 32,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$32',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '17'
                                }
                              ],
                              group: 0,
                              text: '17',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 34,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$33',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '18'
                                }
                              ],
                              group: 0,
                              text: '18',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 36,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$34',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '19'
                                }
                              ],
                              group: 0,
                              text: '19',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 38,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$35',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '20'
                                }
                              ],
                              group: 0,
                              text: '20',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 40,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$36',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '21'
                                }
                              ],
                              group: 0,
                              text: '21',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 42,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$37',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '22'
                                }
                              ],
                              group: 0,
                              text: '22',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 44,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$38',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '23'
                                }
                              ],
                              group: 0,
                              text: '23',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 46,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$39',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '24'
                                }
                              ],
                              group: 0,
                              text: '24',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 48,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$40',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '25'
                                }
                              ],
                              group: 0,
                              text: '25',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 50,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$41',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '26'
                                }
                              ],
                              group: 0,
                              text: '26',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 52,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$42',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '27'
                                }
                              ],
                              group: 0,
                              text: '27',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 54,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$43',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '28'
                                }
                              ],
                              group: 0,
                              text: '28',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 56,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$44',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '29'
                                }
                              ],
                              group: 0,
                              text: '29',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 58,
                              zone: 0
                            },
                            {
                              unitKey: null,
                              allowedSsrs: [],
                              assignable: false,
                              availability: 'Unknown',
                              designator: '$45',
                              properties: [
                                {
                                  code: 'LR',
                                  value: '30'
                                }
                              ],
                              group: 0,
                              text: '30',
                              type: UnitType.LabelRuler,
                              x: 5,
                              y: 60,
                              zone: 0
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        }
      }
    }
  }
};

export const unitsWithPax: UnitInformation[] = cloneDeep(units);
unitsWithPax[0].availability = 'Reserved';
unitsWithPax[0].passenger = MockPassenger1A;

export const unitsWithPaxBreezeThruNoSeatChange: UnitInformation[] =
  cloneDeep(unitsWithPax);
unitsWithPaxBreezeThruNoSeatChange[0].passenger =
  MockPassenger1ABreezeThruNoSeatChange;

export const unitsWithPaxBreezeThruSeatChange: UnitInformation[] =
  cloneDeep(unitsWithPax);
unitsWithPaxBreezeThruSeatChange[0].passenger =
  MockPassenger1ABreezeThruSeatChange;
