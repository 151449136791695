import { GraphResponse, ManifestResponse, Passenger, SSR } from '../nav-models';

export const MockManifest: GraphResponse<ManifestResponse> = {
  data: {
    manifest: {
      manifest: {
        checkInLid: 126,
        market: {
          origin: 'SFO',
          destination: 'CHS',
          departureDate: '2022-08-25T00:00:00',
          identifier: {
            identifier: '101'
          }
        },
        passengerList: [
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96220,
            name: {
              first: 'Professor',
              last: 'Dumbuldor',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1900-04-04T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CWAD',
                            name: 'Child With Adult'
                          },
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          }
                        ],
                        unitDesignator: '19F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96219,
            name: {
              first: 'Hermionie',
              last: 'Granger',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-02-02T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          }
                        ],
                        unitDesignator: '18F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96215,
            name: {
              first: 'Jonny',
              last: 'Jr',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-05-05T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '12F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96221,
            name: {
              first: 'Haggard',
              last: 'McHaggy',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1950-03-03T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '20F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96217,
            name: {
              first: 'Darco',
              last: 'Mouthoi',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-04-04T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '15F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96216,
            name: {
              first: 'Harry',
              last: 'Potter',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-02-02T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '13F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96218,
            name: {
              first: 'Ron',
              last: 'Weasley',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-03-03T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '17F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96214,
            name: {
              first: 'Jason',
              last: 'Wisniewski',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '16F'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  loading: false,
  networkStatus: 200
};

export const MockManifestSortedSeats: GraphResponse<ManifestResponse> = {
  data: {
    manifest: {
      manifest: {
        checkInLid: 126,
        market: {
          origin: 'SFO',
          destination: 'CHS',
          departureDate: '2022-08-25T00:00:00',
          identifier: {
            identifier: '101'
          }
        },
        passengerList: [
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96215,
            name: {
              first: 'Jonny',
              last: 'Jr',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-05-05T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '12F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96216,
            name: {
              first: 'Harry',
              last: 'Potter',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-02-02T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '13F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96217,
            name: {
              first: 'Darco',
              last: 'Mouthoi',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-04-04T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '15F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96214,
            name: {
              first: 'Jason',
              last: 'Wisniewski',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '16F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96218,
            name: {
              first: 'Ron',
              last: 'Weasley',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-03-03T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '17F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96219,
            name: {
              first: 'Hermionie',
              last: 'Granger',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1999-02-02T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          }
                        ],
                        unitDesignator: '18F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96220,
            name: {
              first: 'Professor',
              last: 'Dumbuldor',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1900-04-04T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CWAD',
                            name: 'Child With Adult'
                          },
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          }
                        ],
                        unitDesignator: '19F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96221,
            name: {
              first: 'Haggard',
              last: 'McHaggy',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1950-03-03T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    market: {
                      identifier: {
                        identifier: '101'
                      },
                      origin: 'SFO',
                      destination: 'CHS'
                    },
                    legs: [
                      {
                        market: {
                          identifier: {
                            identifier: '101'
                          },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [
                          {
                            code: 'CB1',
                            name: 'Checked Bag One'
                          },
                          {
                            code: 'CB2',
                            name: 'Checked Bag Two'
                          },
                          {
                            code: 'CB3',
                            name: 'Checked Bag Three'
                          }
                        ],
                        unitDesignator: '20F'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  loading: false,
  networkStatus: 200
};

export const MockManifestUnsortedSeats: GraphResponse<ManifestResponse> = {
  data: {
    manifest: {
      manifest: {
        checkInLid: 126,
        market: {
          origin: 'SFO',
          destination: 'CHS',
          departureDate: '2022-08-25T00:00:00',
          identifier: {
            identifier: '101'
          }
        },
        passengerList: [
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96215,
            name: {
              first: 'Prince',
              last: 'Andrew',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '1A'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96217,
            name: {
              first: 'Pierre',
              last: 'Buzukov',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '2F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96216,
            name: {
              first: 'Boris',
              last: 'Drubetskoy',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '20F'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96226,
            name: {
              first: 'Emperor',
              last: 'Alexander',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '10A'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96218,
            name: {
              first: 'Helene',
              last: 'Kuragina',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96219,
            name: {
              first: 'Mary',
              last: 'Bolkonski',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96225,
            name: {
              first: 'Napoleon',
              last: 'Bonaparte',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '1B'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96224,
            name: {
              first: 'Anatole',
              last: 'Kuragin',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '2D'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96227,
            name: {
              first: 'Natasha',
              last: 'Rostova',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '10B'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96228,
            name: {
              first: 'Dorian',
              last: 'Gray',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '20D'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96229,
            name: {
              first: 'Nicholas',
              last: 'Rostov',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '22D'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96213,
            name: {
              first: 'Mikhail',
              last: 'Kutuzov',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '11A'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            recordLocator: 'H5JY5Z',
            passengerId: 96212,
            name: {
              first: 'Leo',
              last: 'Tolstoy',
              title: null
            },
            infant: null,
            passengerInformation: {
              dateOfBirth: '1995-05-27T00:00:00'
            },
            services: [
              {
                segments: [
                  {
                    segmentKey:
                      'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
                    legs: [
                      {
                        market: {
                          identifier: { identifier: '101' },
                          origin: 'SFO',
                          destination: 'CHS'
                        },
                        ssrs: [],
                        unitDesignator: '20A'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  loading: false,
  networkStatus: 200
};

export const MockManifestNoPax: GraphResponse<ManifestResponse> = {
  data: {
    manifest: {
      manifest: {
        checkInLid: 126,
        market: {
          origin: 'SFO',
          destination: 'CHS',
          departureDate: '2022-08-25T00:00:00',
          identifier: {
            identifier: '101'
          }
        },
        passengerList: []
      }
    }
  },
  loading: false,
  networkStatus: 200
};

export const MockPassenger: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          legs: [
            {
              market: {
                identifier: { identifier: '101' },
                origin: 'SFO',
                destination: 'CHS'
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '15F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerBreezeThruNoSeatChange: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: false,
          legs: [
            {
              market: {
                destination: 'SFO',
                origin: 'PVU',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '15F'
            },
            {
              market: {
                destination: 'SBD',
                origin: 'SFO',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '15F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerBreezeThruSeatChange: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: true,
          legs: [
            {
              market: {
                destination: 'SFO',
                origin: 'PVU',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '15F'
            },
            {
              market: {
                destination: 'SBD',
                origin: 'SFO',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '16F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerNoSeat: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          legs: [
            {
              market: {
                identifier: { identifier: '101' },
                origin: 'SFO',
                destination: 'CHS'
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: null
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerNoBirthdate: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: undefined
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          legs: [
            {
              market: {
                identifier: { identifier: '101' },
                origin: 'SFO',
                destination: 'CHS'
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '15F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerNoSSRS: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          legs: [
            {
              market: {
                identifier: { identifier: '101' },
                origin: 'SFO',
                destination: 'CHS'
              },
              ssrs: [],
              unitDesignator: '15F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPax: Passenger[] = [
  {
    recordLocator: 'abc',
    passengerId: 123,
    name: {
      first: 'Prof',
      last: 'Dumbulldoor'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: undefined,
            legs: [
              {
                market: {
                  identifier: { identifier: '101' },
                  origin: 'SFO',
                  destination: 'CHS'
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13B'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    recordLocator: 'abc',
    passengerId: 124,
    name: {
      first: 'Ron',
      last: 'Weazly'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: undefined,
            legs: [
              {
                market: {
                  identifier: { identifier: '101' },
                  origin: 'SFO',
                  destination: 'CHS'
                },
                ssrs: [
                  {
                    code: 'WCHS',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13C'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const MockPaxBreezeThruNSC: Passenger[] = [
  {
    recordLocator: 'abc',
    passengerId: 123,
    name: {
      first: 'Prof',
      last: 'Dumbulldoor'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: {
              destination: 'SBD',
              origin: 'PVU',
              identifier: {
                identifier: '600'
              }
            },
            isBreezeThru: true,
            seatChangeRequired: false,
            legs: [
              {
                market: {
                  destination: 'SFO',
                  origin: 'PVU',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13B'
              },
              {
                market: {
                  destination: 'SBD',
                  origin: 'SFO',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13B'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    recordLocator: 'abc',
    passengerId: 124,
    name: {
      first: 'Ron',
      last: 'Weazly'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: {
              destination: 'SBD',
              origin: 'PVU',
              identifier: {
                identifier: '600'
              }
            },
            isBreezeThru: true,
            seatChangeRequired: false,
            legs: [
              {
                market: {
                  destination: 'SFO',
                  origin: 'PVU',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHS',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13C'
              },
              {
                market: {
                  destination: 'SBD',
                  origin: 'SFO',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHS',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13C'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const MockPaxBreezeThruSC: Passenger[] = [
  {
    recordLocator: 'abc',
    passengerId: 123,
    name: {
      first: 'Prof',
      last: 'Dumbulldoor'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: {
              destination: 'SBD',
              origin: 'PVU',
              identifier: {
                identifier: '600'
              }
            },
            isBreezeThru: true,
            seatChangeRequired: true,
            legs: [
              {
                market: {
                  destination: 'SFO',
                  origin: 'PVU',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13B'
              },
              {
                market: {
                  destination: 'SBD',
                  origin: 'SFO',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '14B'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    recordLocator: 'abc',
    passengerId: 124,
    name: {
      first: 'Ron',
      last: 'Weazly'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: {
              destination: 'SBD',
              origin: 'PVU',
              identifier: {
                identifier: '600'
              }
            },
            isBreezeThru: true,
            seatChangeRequired: true,
            legs: [
              {
                market: {
                  destination: 'SFO',
                  origin: 'PVU',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHS',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13C'
              },
              {
                market: {
                  destination: 'SBD',
                  origin: 'SFO',
                  identifier: {
                    identifier: '600'
                  }
                },
                ssrs: [
                  {
                    code: 'WCHS',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '14C'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const PaxWithLongName: Passenger[] = [
  {
    recordLocator: 'abc',
    passengerId: 123,
    name: {
      first: 'Hubert Blaine',
      last: 'Wolfeschlegelsteinhausenbergerdorff'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: undefined
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: undefined,
            legs: [
              {
                market: {
                  identifier: { identifier: '101' },
                  origin: 'SFO',
                  destination: 'CHS'
                },
                ssrs: [
                  {
                    code: 'WCHR',
                    name: 'WHEELCHAIRS'
                  }
                ],
                unitDesignator: '13B'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const MockPassenger1A: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          legs: [
            {
              market: {
                identifier: {
                  identifier: '101'
                },
                origin: 'SFO',
                destination: 'CHS'
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '1A'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassenger1ABreezeThruNoSeatChange: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: false,
          market: {
            origin: 'PVU',
            destination: 'SBD',
            identifier: {
              identifier: '600'
            }
          },
          legs: [
            {
              market: {
                origin: 'PVU',
                destination: 'SFO',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '1A'
            },
            {
              market: {
                origin: 'SFO',
                destination: 'SBD',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '1A'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassenger1ABreezeThruSeatChange: Passenger = {
  recordLocator: 'H5JY5Z',
  passengerId: 96217,
  name: {
    first: 'Darco',
    last: 'Mouthoi',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1999-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: true,
          market: {
            origin: 'PVU',
            destination: 'SBD',
            identifier: {
              identifier: '600'
            }
          },
          legs: [
            {
              market: {
                origin: 'PVU',
                destination: 'SFO',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '1A'
            },
            {
              market: {
                origin: 'PVU',
                destination: 'SFO',
                identifier: {
                  identifier: '600'
                }
              },
              ssrs: [
                {
                  code: 'CB1',
                  name: 'Checked Bag One'
                },
                {
                  code: 'CB2',
                  name: 'Checked Bag Two'
                },
                {
                  code: 'CB3',
                  name: 'Checked Bag Three'
                }
              ],
              unitDesignator: '2A'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerBTWithSsrs: Passenger = {
  recordLocator: 'H5JY5K',
  passengerId: 96237,
  name: {
    first: 'Haggard',
    last: 'McHaggy'
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1900-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: false,
          market: {
            origin: 'PVU',
            destination: 'SFO',
            identifier: {
              identifier: '600'
            }
          },
          legs: [
            {
              ssrs: [
                {
                  code: 'WCHS',
                  name: 'Wheelchair'
                }
              ],
              market: {
                identifier: { identifier: '600' },
                origin: 'PVU',
                destination: 'SFO'
              },
              unitDesignator: '19F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockPassengerWithSsrs: Passenger = {
  recordLocator: 'H5JY5K',
  passengerId: 96237,
  name: {
    first: 'Haggard',
    last: 'McHaggy'
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1900-04-04T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
          isBreezeThru: true,
          seatChangeRequired: false,
          market: {
            identifier: { identifier: '101' },
            origin: 'SFO',
            destination: 'CHS'
          },
          legs: [
            {
              ssrs: [
                {
                  code: 'WCHS',
                  name: 'Wheelchair'
                },
                {
                  code: 'LANG',
                  name: 'Language Assistance'
                },
                {
                  code: 'INFT',
                  name: 'Infant in Lap'
                },
                {
                  code: 'ARMD',
                  name: 'Armed Guest'
                }
              ],
              market: {
                identifier: { identifier: '101' },
                origin: 'SFO',
                destination: 'CHS'
              },
              unitDesignator: '19F'
            }
          ]
        }
      ]
    }
  ]
};

export const MockBreezeThruPassenger: Passenger = {
  recordLocator: 'A7BCMG',
  passengerId: 99321,
  name: {
    first: 'Pierre',
    last: 'Bezukov',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1990-01-01T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDYwMH4gfn5QVlV_MDkvMjQvMjAyMiAwMDowMH5TQkR_MDEvMDEvMTgwMCAwMDowMH5TRk9_',
          market: {
            identifier: {
              identifier: '600'
            },
            origin: 'PVU',
            destination: 'SBD'
          },
          legs: [
            {
              market: {
                identifier: {
                  identifier: '600'
                },
                origin: 'SFO',
                destination: 'SBD'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '1A'
            },
            {
              market: {
                identifier: {
                  identifier: '600'
                },
                origin: 'PVU',
                destination: 'SFO'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '1A'
            }
          ],
          isBreezeThru: true,
          seatChangeRequired: false
        }
      ]
    }
  ]
};

export const MockBreezeThruPassengerDiffSeat: Passenger = {
  recordLocator: 'A7BCMG',
  passengerId: 99321,
  name: {
    first: 'Pierre',
    last: 'Bezukov',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1990-01-01T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDYwMH4gfn5QVlV_MDkvMjQvMjAyMiAwMDowMH5TQkR_MDEvMDEvMTgwMCAwMDowMH5TRk9_',
          market: {
            identifier: {
              identifier: '600'
            },
            origin: 'PVU',
            destination: 'SBD'
          },
          legs: [
            {
              market: {
                identifier: {
                  identifier: '600'
                },
                origin: 'SFO',
                destination: 'SBD'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '2F'
            },
            {
              market: {
                identifier: {
                  identifier: '600'
                },
                origin: 'PVU',
                destination: 'SFO'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '1A'
            }
          ],
          isBreezeThru: true,
          seatChangeRequired: true
        }
      ]
    }
  ]
};

export const MockConnectingPassenger: Passenger = {
  recordLocator: 'A7BCMG',
  passengerId: 99321,
  name: {
    first: 'Andrei',
    last: 'Bolkonsky',
    title: null
  },
  infant: null,
  passengerInformation: {
    dateOfBirth: '1990-01-01T00:00:00'
  },
  services: [
    {
      segments: [
        {
          segmentKey:
            'TVh_IDYwMH4gfn5QVlV_MDkvMjawMemKjAyMiAwMDowMH5TQkR_MDEvMDEvMTgwMCAwMDowMH5TRk9_',
          market: {
            identifier: {
              identifier: '500'
            },
            origin: 'TPA',
            destination: 'PVU'
          },
          legs: [
            {
              market: {
                identifier: {
                  identifier: '500'
                },
                origin: 'TPA',
                destination: 'PVU'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '2F'
            }
          ],
          isBreezeThru: false,
          seatChangeRequired: false
        },
        {
          segmentKey:
            'TVh_IDYwMH4gfn5QVlV_MDkvMjQvMjAyMiAwMDowMH5TQkR_MDEvMDEvMTgwMCAwMDowMH5TRk9_',
          market: {
            identifier: {
              identifier: '600'
            },
            origin: 'PVU',
            destination: 'SFO'
          },
          legs: [
            {
              market: {
                identifier: {
                  identifier: '600'
                },
                origin: 'PVU',
                destination: 'SFO'
              },
              ssrs: [
                {
                  code: 'PECO',
                  name: 'Premium Economy Seat Assignment'
                }
              ],
              unitDesignator: '1A'
            }
          ],
          isBreezeThru: false,
          seatChangeRequired: false
        }
      ]
    }
  ]
};

export const MockPaxNoSSRS: Passenger[] = [
  {
    recordLocator: 'abc',
    passengerId: 123,
    name: {
      first: 'Prof',
      last: 'Dumbulldoor'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: undefined,
            legs: [
              {
                market: {
                  identifier: { identifier: '101' },
                  origin: 'SFO',
                  destination: 'CHS'
                },
                ssrs: [],
                unitDesignator: '13B'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    recordLocator: 'abc',
    passengerId: 124,
    name: {
      first: 'Ron',
      last: 'Weazly'
    },
    infant: null,
    passengerInformation: {
      dateOfBirth: '04/04/1991'
    },
    services: [
      {
        segments: [
          {
            segmentKey: 'abc',
            market: undefined,
            legs: [
              {
                market: {
                  identifier: { identifier: '101' },
                  origin: 'SFO',
                  destination: 'CHS'
                },
                ssrs: [],
                unitDesignator: '13C'
              }
            ]
          }
        ]
      }
    ]
  }
];
