import styled from '@emotion/styled';
import {Flight, FlightStatus} from '@models';
import { useTheme } from '@emotion/react';
import StatusBadge from '../status-badge/status-badge';
import { format } from 'date-fns';
import { convertAircraftType } from '@utilities';
import {utcToZonedTime} from "date-fns-tz";

/* eslint-disable-next-line */
export interface FlightInfoProps {
  flight: Flight;
}

const Col = styled.div`
  display: grid;
  grid-template-columns: 48% 1% 48%;
`;

const VerticalLine = styled.div(
  ({ theme }) => `
    border-left: 1px solid ${theme.colors.lightBlue};
    height: 60%;
    margin: auto 0;
  `
);
const PadLeft = styled.div`
  padding: 15px 30px 15px 20px;
`;

const PadRight = styled.div`
  padding: 15px 30px 15px 20px;
`;

const StyledFlightInfo = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.nicest};
    font-size: 9.38px;
    font-family: ${theme.fonts.worksSans};
    color: ${theme.colors.white};
  `
);

const Title = styled.div(
  ({ theme }) => `
    color: ${theme.colors.lightBlue};
    padding-bottom: 3px;
    font-size: 9px;
  `
);

const AirPort = styled.div(
  ({ theme }) => `
    font-size: 14px;
    font-weight: bold;
    font-family: ${theme.fonts.secondary};
    letter-spacing: 0.13em;
  `
);

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
  align-items: center;
`;

const AlignRight = styled.div`
  display: flex;
  justify-content: right;
`;

const MarginRight = styled.div`
  margin-bottom: 2.5px;
`;

const MarginLeft = styled.div`
  margin-bottom: 2px;
`;

const Bold = styled.div`
  font-weight: bold;
`;

export function FlightInfo({ flight }: FlightInfoProps) {
  return (
    <StyledFlightInfo>
      <Col>
        <PadLeft>
          <Title> Depart </Title>
          <MarginLeft>
            <Row>
              <AirPort>{flight.actualDepartureAirport} </AirPort>
              <AlignRight>
                <StatusBadge flightStatus={flight.status} primary={false} />
              </AlignRight>
            </Row>
            <Row>
              Departure{' '}
              <Bold>
                {flight.status === FlightStatus.Delayed || flight.status === FlightStatus.Canceled ?
                  flight.estimatedTimes?.estimatedBlockOff && flight.offsetDa && format(utcToZonedTime(new Date(flight.estimatedTimes.estimatedBlockOff), flight.offsetDa), 'H:mm')
                  : flight.stdLocal && format(new Date(flight.stdLocal), 'H:mm')}{' '}
              </Bold>
            </Row>

            <Row>
              Gate{' '}
              <Bold>{flight.departureGate ? flight.departureGate : '--'}</Bold>
            </Row>

            <Row>
              Aircraft{' '}
              <Bold>
                {flight.aircraftType
                  ? convertAircraftType(flight.aircraftType)
                  : '--'}
              </Bold>
            </Row>

            <Row>
              Tail Number{' '}
              <Bold>{flight.tailNumber ? flight.tailNumber : '--'}</Bold>
            </Row>
          </MarginLeft>
        </PadLeft>
        <VerticalLine></VerticalLine>
        <PadRight>
          <Title> Arrive </Title>
          <MarginRight>
            <Row>
              <AirPort> {flight.actualArrivalAirport}</AirPort>
            </Row>
            <Row>
              Arriving{' '}
              <Bold>
                {flight.staLocal && format(new Date(flight.staLocal), 'H:mm')}
              </Bold>
            </Row>

            <Row>
              Estimated{' '}
              <Bold>
                {flight.estimatedTimes?.estimatedBlockOn &&
                  format(
                    new Date(flight.estimatedTimes?.estimatedBlockOn),
                    'H:mm'
                  )}
              </Bold>
            </Row>

            <Row>
              Gate <Bold>{flight.arrivalGate ? flight.arrivalGate : '--'}</Bold>
            </Row>
          </MarginRight>
        </PadRight>
      </Col>
    </StyledFlightInfo>
  );
}

export default FlightInfo;
