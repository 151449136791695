import Menu from '@components/lib/menu/menu';
import React, { useEffect, useState } from 'react';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject
} from '@apollo/client';
import {
  FlightDetailsPage,
  GuestListPage,
  LoginPage,
  SchedulePage,
  SeatMapPage,
  SsrDetailsPage
} from './pages';
import { SplashScreen } from '@capacitor/splash-screen';
import { useSession } from '@hooks';
import { clientFactory } from '@utilities';
import '../imports';
import './theme/variables.css';
import {CREWHUB_ROUTES, NavigationLink} from '@models';
import { airplaneOutline, airplaneSharp } from 'ionicons/icons';
import Amplify from '@aws-amplify/core';
import * as appsync from '../AppSync';
import { environment } from 'environments/environment';

Amplify.configure(appsync.config);

setupIonicReact({
  mode: 'ios'
});

const App: React.FC = () => {
  console.log("Loading environment variables for ", environment.environment);
  const navigationLinks: NavigationLink[] = [
    {
      title: 'Schedule',
      url: CREWHUB_ROUTES.SCHEDULE,
      iosIcon: airplaneOutline,
      mdIcon: airplaneSharp
    }
  ];

  const [token, setToken] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(
    clientFactory('', `${environment.navblueUrl}/graph/authenticated/flights` || '')
  );
  const { initialize, acquireTokenSilent  } = useSession();

  useEffect(() => {
    SplashScreen.hide().catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    initialize().then(() => {
      acquireTokenSilent([environment.apiScope])
      .then((accessToken) => {
        setToken(accessToken);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        console.log(error);
      });
    });
  }, [])

  useEffect(() => {
    const client = clientFactory(
      token,
      `${environment.navblueUrl}/graph/authenticated/flights` || ''
    );
    setClient(client);
  }, [token]);

  function handleLogin() {
    acquireTokenSilent([environment.apiScope])
      .then((accessToken) => {
        setToken(accessToken);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setTimeout(() => handleLogin(), 100);
      });
  }

  if (isLoggedIn) {
    return (
      <ApolloProvider client={client}>
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Menu
                links={navigationLinks}
                redirectToOnSignout= {CREWHUB_ROUTES.LOGIN}
              />
              <IonRouterOutlet id="main">
                <Route
                  path={`${CREWHUB_ROUTES.SEATMAP}/:flightId`}
                  component={SeatMapPage}
                  exact
                />
                <Route
                  path={`${CREWHUB_ROUTES.FLIGHT_DETAILS}/:flightId`}
                  component={FlightDetailsPage}
                  exact
                />
                <Route
                  path={`${CREWHUB_ROUTES.GUEST_LIST}/:flightId`}
                  component={GuestListPage}
                  exact
                />
                <Route
                  path={CREWHUB_ROUTES.SCHEDULE}
                  component={SchedulePage}
                  exact
                />
                <Route
                  path={`${CREWHUB_ROUTES.SSR_DETAILS}/:flightId`}
                  component={SsrDetailsPage}
                />
                <Redirect
                  from={CREWHUB_ROUTES.EMPTY}
                  to={CREWHUB_ROUTES.SCHEDULE}
                  exact
                />
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </ApolloProvider>
    );
  } else {
    return (
      <IonApp>
        <LoginPage authenticationCallback={handleLogin}/>
      </IonApp>
    );
  }
};

export default App;
