import {
  ScheduleFlight,
  Manifest,
  ManifestUpdateResponse,
  ManifestSubscription
} from '@models';
import * as appsyncMethods from '@utilities/src/lib/app-sync-utils/app-sync-utils';
import { createManifestName } from '@utilities';
import { useEffect, useState } from 'react';
import { manifestUpdatesSubscription } from '@graphql/lib/manifest-updates-subscription';
import { useSession } from '../msal/msal';
import {useCrewHubStore, useCrewHubStoreDispatch} from "../crew-hub-store/crew-hub-store";
import {CrewHubActionTypes} from "../crew-hub-store/crew-hub-store-actions";
import { GraphQLSubscription } from '@aws-amplify/api';
import { environment } from 'environments/environment';

export function useManifestSubscription() {
  const dispatch = useCrewHubStoreDispatch();
  const [flightSchedule, setFlightSchedule] = useState<ScheduleFlight[]>([]);
  const [token, setToken] = useState<string>('');
  const store = useCrewHubStore();
  const { acquireTokenSilent } = useSession();
  const online = store.isOnline;

  acquireTokenSilent([environment.apiScope]).then((accessToken) => {
    setToken(accessToken);
  })

  useEffect(() => {
    if (store && store.schedule && store.schedule.length !== 0 && online) {
      setFlightSchedule(store.schedule);
    }
  }, [store]);

  useEffect(() => {
    if (flightSchedule.length !== 0 && token && online) {
      // clear all existing subscriptions
      if(store?.manifestSubscriptions) {
        store.manifestSubscriptions.forEach((sub) => {
          try {
            sub.subscription.unsubscribe();
          } catch {
            console.log("Failed to unsubscribe");
          }
        });
      }
      
      dispatch({
        type: CrewHubActionTypes.clearManifestSubscriptions,
        payload: null
      })

      flightSchedule.forEach((flight: ScheduleFlight) => {
        const manifestName = createManifestName(flight)

        const subscription: GraphQLSubscription<any> = appsyncMethods.subscribe(
          manifestUpdatesSubscription,
          { manifestName: manifestName },
          (response: ManifestUpdateResponse) => {
            const updatedManifest: Manifest = response.manifestUpdates.manifest as Manifest;

            dispatch({
              type: CrewHubActionTypes.updateManifest,
              payload: updatedManifest
            });
          },
          token
        );

        const payload: ManifestSubscription = {
          manifestName: manifestName,
          subscription: subscription
        }

        // store subscription
        dispatch({
          type: CrewHubActionTypes.setManifestSubscriptions,
          payload: payload
        });
      });
    }
  }, [flightSchedule, token, online]);
}
