import { Flight } from '../nav-models';
import { AircrafType, FlightStatus } from '../constants';
import { add } from 'date-fns';
import {
  MockCrew,
  MockCrew_NoFirstNames,
  MockCrew_NoLastNames,
  MockCrew_NoRank
} from './mock-crew';

const today = new Date().toISOString();
const tomorrow = add(new Date(), { days: 1 }).toISOString();

export const baseFlight: Flight = {
  actualArrivalAirport: 'CHS',
  actualDepartureAirport: 'SFO',
  departureGate: 'B1',
  arrivalGate: 'A22',
  flightDate: '',
  flightNumber: '101',
  offsetAa: '-04:00',
  offsetDa: '-07:00',
  scheduledArrivalAirport: 'CHS',
  scheduledDepartureAirport: 'SFO',
  sta: '2022-06-30T00:20:00+00:00',
  staLocal: '2022-06-30T00:20:00+00:00',
  status: FlightStatus.OnTime,
  std: '2022-06-29T19:10:00+00:00',
  stdLocal: today,
  reportTime: '2022-06-29T18:25:00+00:00',
  aircraftType: AircrafType.E195,
  uniqueId: '20315',
  tailNumber: 'N100BZ',
  estimatedTimes: {
    estimatedTakeOff: '2022-08-03T19:10:00+00:00',
    estimatedTouchDown: '2022-08-04T00:20:00+00:00',
    estimatedBlockOff: '2022-08-03T19:10:00+00:00',
    estimatedBlockOn: '2022-08-04T00:20:00+00:00'
  }
};

export const OnTimeFlight: Flight = {
  ...baseFlight,
  status: FlightStatus.OnTime,
  crew: MockCrew,
  uniqueId: '20314'
};

export const DelayedFlight: Flight = {
  ...baseFlight,
  status: FlightStatus.Delayed,
  crew: MockCrew,
  uniqueId: '20313'
};

export const CanceledFlight: Flight = {
  ...baseFlight,
  status: FlightStatus.Canceled,
  crew: MockCrew,
  uniqueId: '20312'
};

export const CompletedFlight: Flight = {
  ...baseFlight,
  status: FlightStatus.Completed,
  crew: MockCrew,
  uniqueId: '20313'
};

export const NoDepartureGateFlight: Flight = {
  ...baseFlight,
  departureGate: undefined,
  crew: MockCrew,
  uniqueId: '20311'
};

export const NoArrivalGateFlight: Flight = {
  ...baseFlight,
  arrivalGate: undefined,
  crew: MockCrew,
  uniqueId: '20310'
};

export const NoEstimatedTimesFlight: Flight = {
  ...baseFlight,
  estimatedTimes: undefined,
  crew: MockCrew,
  uniqueId: '20309'
};

export const FlightDepartingToday: Flight = {
  ...baseFlight,
  std: today,
  stdLocal: today,
  departureGate: 'G5',
  crew: MockCrew,
  uniqueId: '20308'
};

export const FlightDepartingTomorrow: Flight = {
  ...baseFlight,
  std: tomorrow,
  stdLocal: tomorrow,
  departureGate: 'G6',
  crew: MockCrew,
  uniqueId: '20307'
};

export const FlightWithUnsortedSeats: Flight = {
  ...baseFlight,
  status: FlightStatus.OnTime,
  crew: MockCrew,
  uniqueId: '20314'
};

export const BreezeThruFlight: Flight = {
  uniqueId: '25743',
  status: FlightStatus.OnTime,
  flightNumber: '600',
  flightDate: '2022-09-24T00:00:00+00:00',
  aircraftType: AircrafType.E190,
  scheduledDepartureAirport: 'PVU',
  scheduledArrivalAirport: 'SFO',
  std: '2022-09-24T14:30:00+00:00',
  sta: '2022-09-24T16:35:00+00:00',
  stdLocal: '2022-09-24T08:30:00',
  staLocal: '2022-09-24T09:35:00',
  offsetDa: '-06:00',
  offsetAa: '-07:00',
  actualDepartureAirport: 'PVU',
  actualArrivalAirport: 'SFO',
  departureGate: 'B1',
  arrivalGate: 'A22',
  reportTime: '2022-06-29T18:25:00+00:00',
  estimatedTimes: {
    estimatedTakeOff: '2022-09-24T14:30:00+00:00',
    estimatedTouchDown: '2022-09-24T15:30:00+00:00'
  },
  crew: MockCrew
};

export const ConnectingFlight: Flight = {
  uniqueId: '25743',
  status: FlightStatus.OnTime,
  flightNumber: '500',
  flightDate: '2022-09-24T00:00:00+00:00',
  aircraftType: AircrafType.E190,
  scheduledDepartureAirport: 'TPA',
  scheduledArrivalAirport: 'PVU',
  std: '2022-09-24T14:30:00+00:00',
  sta: '2022-09-24T16:35:00+00:00',
  stdLocal: '2022-09-24T08:30:00',
  staLocal: '2022-09-24T09:35:00',
  offsetDa: '-06:00',
  offsetAa: '-07:00',
  actualDepartureAirport: 'TPA',
  actualArrivalAirport: 'PVU',
  departureGate: 'B1',
  arrivalGate: 'A22',
  reportTime: '2022-06-29T18:25:00+00:00',
  estimatedTimes: {
    estimatedTakeOff: '2022-09-24T14:30:00+00:00',
    estimatedTouchDown: '2022-09-24T15:30:00+00:00'
  },
  crew: MockCrew
};

export const NoFirstNameCrew: Flight = {
  ...baseFlight,
  crew: MockCrew_NoFirstNames,
  uniqueId: '20306'
};

export const NoLastNameCrew: Flight = {
  ...baseFlight,
  crew: MockCrew_NoLastNames,
  uniqueId: '20305'
};

export const NoRankingCrew: Flight = {
  ...baseFlight,
  crew: MockCrew_NoRank,
  uniqueId: '20304'
};

export const MockFlightData: Flight[] = [
  OnTimeFlight,
  DelayedFlight,
  CanceledFlight,
  NoDepartureGateFlight,
  NoArrivalGateFlight,
  NoEstimatedTimesFlight,
  FlightDepartingToday,
  FlightDepartingTomorrow
];

export const EmptyMockFlightData: Flight[] = [];

export const MockFlightForManifestSeatmap: Flight = {
  actualArrivalAirport: 'CHS',
  actualDepartureAirport: 'SFO',
  departureGate: 'B1',
  arrivalGate: 'A22',
  flightDate: '',
  flightNumber: '101',
  offsetAa: '-04:00',
  offsetDa: '-07:00',
  scheduledArrivalAirport: 'CHS',
  scheduledDepartureAirport: 'SFO',
  sta: '2022-06-30T00:20:00+00:00',
  staLocal: '2022-06-30T00:20:00+00:00',
  status: FlightStatus.OnTime,
  std: '2022-06-29T19:10:00+00:00',
  stdLocal: '2022-06-29T18:10:00+00:00',
  reportTime: '2022-06-29T18:25:00+00:00',
  aircraftType: AircrafType.E195,
  tailNumber: 'N100BZ',
  uniqueId: '20315'
};
