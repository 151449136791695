import styled from '@emotion/styled';
import { IonCol, IonContent, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import wheelchair from '../../../../assets/src/lib/wheelchair.svg';
import multiplessr from '../../../../assets/src/lib/plain-multiple.svg';
import infant from '../../../../assets/src/lib/infant.svg';
import birthday from '../../../../assets/src/lib/birthday.svg';
import serviceAnimal from '../../../../assets/src/lib/service-animal.svg';
import petInCabin from '../../../../assets/src/lib/pet-in-cabin.svg';
import medicalCase from '../../../../assets/src/lib/medical-case.svg';

export interface LegendModalProps {
  setShowModal: (showModal: boolean) => void;
}

//add any new ssr icons below and it will be added to legend
const legendIconList: any = [
  {
    title: 'Multiple special services',
    icon: multiplessr
  },
  {
    title: 'Lap infant',
    icon: infant
  },
  {
    title: 'Birthday',
    icon: birthday
  },
  {
    title: 'Pet In Cabin',
    icon: petInCabin
  },
  {
    title: 'Medical Case',
    icon: medicalCase
  }
];

const StyledLegendModal = styled.div(
  ({ theme }) => `
  color: ${theme.colors.lightGrey};
  font-family: ${theme.fonts.worksSans}
  font-weight: 400;
  font-size:16px;
  background-color: ${theme.colors.primary}
`
);

const Border = styled.div(
  ({ theme }) => `
  border-bottom: 0.5px solid ${theme.colors.accentGrey};
`
);
const EmptySeat = styled.div`
  height: 23px;
  width: 20px;
  gridcolumn: 1 / span 2;
  gridrow: 1 / span 1;
  msgridcolumns: 1 / span 2;
  msgridrows: 1 / span 1;
  position: relative;
  background-color: rgba(128, 128, 128, 0.5);
  border: 1px solid rgba(128, 128, 128, 0.5);
`;
const OccupiedFirstClassSeat = styled.div(
  ({ theme }) => `
  height: 23px;
  width: 20px;
  gridColumn: 1 / span 2;
  gridRow: 1 /span 1;
  msGridColumns: 1 / span 2;
  msGridRows: 1 /span 1;
  position: relative;
  background-color: ${theme.colors.nicest};
  border: 1px solid rgba(128, 128, 128, 0.5);
  `
);

const OccupiedExtraLegroomSeat = styled.div(
  ({ theme }) => `
  height: 23px;
  width: 20px;
  gridColumn: 1 / span 2;
  gridRow: 1 /span 1;
  msGridColumns: 1 / span 2;
  msGridRows: 1 /span 1;
  position: relative;
  background-color: ${theme.colors.nicer};
  border: 1px solid rgba(128, 128, 128, 0.5);
  `
);
const OccupiedStandardSeat = styled.div(
  ({ theme }) => `
  height: 23px;
  width: 20px;
  gridColumn: 1 / span 2;
  gridRow: 1 /span 1;
  msGridColumns: 1 / span 2;
  msGridRows: 1 /span 1;
  position: relative;
  background-color: ${theme.colors.nice};
  border: 1px solid rgba(128, 128, 128, 0.5);
  `
);
const BreezeThroughNoChange = styled.div(`
    height: 0;
    width: 0;
    border-top: 20px solid transparent;
    border-left: 20px solid white;
    margin-left: 4px
  `);
const BreezeThroughChange = styled.div(`
    height: 0;
    width: 0;
    border-top: 20px solid transparent;
    border-left: 20px solid #FF527B;
    margin-left: 4px
  `);
const ExitRow = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.lightRed};
  height: 23px;
  width: 6px;
  margin-left: 8px;
`
);

//add any custom css items here
const nonIconList: any = [
  {
    title: 'Exit Row',
    div: ExitRow
  },
  {
    title: 'Empty seat',
    div: EmptySeat
  },
  {
    title: 'Standard',
    div: OccupiedStandardSeat
  },
  {
    title: 'Extra Legroom',
    div: OccupiedExtraLegroomSeat
  },
  {
    title: 'First Class',
    div: OccupiedFirstClassSeat
  },
  {
    title: 'BreezeThru',
    div: BreezeThroughNoChange
  },
  {
    title: 'BreezeThru with a seat change',
    div: BreezeThroughChange
  }
];

export function LegendModal({ setShowModal }: LegendModalProps) {
  return (
    <IonContent>
      <StyledLegendModal>
        <IonIcon
          slot="start"
          icon={closeCircleOutline}
          onClick={() => setShowModal(false)}
          style={{
            padding: '10px',
            margin: '0',
            height: '40px',
            width: '40px'
          }}
        ></IonIcon>
        <Border />

        {nonIconList.map(
          (item: { title: string; div: string }, index: number) => {
            return (
              <Border key={index} data-testid={'icon-list-item'}>
                <IonGrid style={{ padding: '5px 9px 5px 9px', height: '42px' }}>
                  <IonRow>
                    <IonCol
                      className="ion-float-left"
                      size="2"
                      style={{
                        display: `flex`,
                        justifyContent: `left`,
                        alignItems: `center`
                      }}
                    >
                      <item.div />
                    </IonCol>
                    <IonCol
                      size="10"
                      style={{
                        display: `flex`,
                        alignItems: `center`
                      }}
                    >
                      {item.title}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Border>
            );
          }
        )}
        {/*any item with 2 lines of text must be removed from loop to flex height*/}
        <Border data-testid={'icon-list-item'}>
          <IonGrid style={{ padding: '2px, 9px, 2px, 9x' }}>
            <IonRow style={{ Height: '50px' }}>
              <IonCol
                size="2"
                style={{
                  display: `flex`,
                  justifyContent: `left`,
                  alignItems: 'center',
                  paddingLeft: '9px'
                }}
              >
                <img alt="icon" src={wheelchair}></img>
              </IonCol>
              <IonCol
                size="10"
                style={{
                  display: `flex`,
                  alignItems: 'center'
                }}
              >
                WCHC, WCOB, WCBD, WCHR, WCBW, WCHS
              </IonCol>
            </IonRow>
          </IonGrid>
        </Border>
        <Border data-testid={'icon-list-item'}>
          <IonGrid style={{ padding: '2px, 9px, 2px, 9x' }}>
            <IonRow style={{ Height: '50px' }}>
              <IonCol
                size="2"
                style={{
                  display: `flex`,
                  justifyContent: `left`,
                  alignItems: 'center',
                  paddingLeft: '9px'
                }}
              >
                <img alt="icon" src={serviceAnimal}></img>
              </IonCol>
              <IonCol
                size="10"
                style={{
                  display: `flex`,
                  alignItems: 'center'
                }}
              >
                SVAV, SVAN
              </IonCol>
            </IonRow>
          </IonGrid>
        </Border>

        {legendIconList.map(
          (item: { title: string; icon: string }, index: number) => {
            return (
              <Border key={index} data-testid={'icon-list-item'}>
                <IonGrid
                  style={{ padding: '1px 9px 1px 9px', minHeight: '42px' }}
                >
                  <IonRow>
                    <IonCol
                      size="2"
                      style={{
                        display: `flex`,
                        justifyContent: `left`,
                        alignItems: 'center',
                        paddingLeft: '5px'
                      }}
                    >
                      <img
                        style={{ margin: '0' }}
                        alt="icon"
                        src={item.icon}
                      ></img>
                    </IonCol>
                    <IonCol
                      size="10"
                      style={{
                        display: `flex`,
                        alignItems: 'center'
                      }}
                    >
                      {item.title}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Border>
            );
          }
        )}
      </StyledLegendModal>
    </IonContent>
  );
}
