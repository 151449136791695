import styled from '@emotion/styled';
import {Flight, Manifest, Passenger, SSR} from '@models';
import React, { useState } from 'react';
import {
  findLeg,
  findManifestForFlight, 
  getPaxSSRCodesByType,
  getSSRListFromFlightAndManifest,
  sortPaxBySeat
} from '@utilities';
import { IonModal } from '@ionic/react';
import GuestInfoModal from '../guest-info-modal/guest-info-modal';
import { useCrewHubStore } from '@hooks';
import SsrIcon from "../ssr-icon/ssr-icon";

export interface SsrDetailProps {
  flight: Flight;
}

const StyledSsrList = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.primary};
    color: ${theme.colors.lightGrey};
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.worksSans};
    font-weight: 400;
  `
);

const Title = styled.h2(
  ({ theme }) => `
    border-top: 0.5px solid ${theme.colors.accentGrey};
    padding: 2% ${theme.padding.leftEdge};
    color: ${theme.colors.white};
    background: ${theme.colors.darkBlue};
    font-family: ${theme.fonts.secondary};
    display: block;
    margin: 0 0 0 0;
    font-size: ${theme.fontSizes.medium};
  `
);

const Row = styled.div(
  ({ theme }) => `
    border-top: 0.5px solid ${theme.colors.accentGrey};
    display: grid;
    grid-template-columns: 20% 30% 50%;
    min-height: 40px;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: ${theme.padding.leftEdge};
  `
);

const SeatNo = styled.div(
  ({ theme }) => `
    color: ${theme.colors.accentGrey};
    padding: 2%;
    display: flex;
    align-items: center;
  `
);

const SSRCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
`;

const PAXContainer = styled.div`
  display: flex;
  align-items: center;
`

const SSRCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export function SsrDetail({ flight }: SsrDetailProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [passenger, setPassenger] = useState<Passenger>();
  const store = useCrewHubStore();
  const manifest: Manifest | undefined =
    store.manifests &&
    flight &&
    findManifestForFlight(store.manifests, flight);
  const ssrList =
    flight &&
    manifest &&
    getSSRListFromFlightAndManifest(flight, manifest);

  const ssrs = ssrList || [];
  const notEmpty = ssrs.some((ssr) => ssr.pax.length > 0);

  const generateSSR = (ssrName: string, code: string,): SSR => {
    return {
      code,
      name: ssrName
    }
  }

  const setPaxAndShowModal = (passenger: Passenger) => {
    if (!passenger) {
      return;
    }
    setPassenger(passenger);
    setShowModal(true);
  };

  return (
    <StyledSsrList>
      {notEmpty ? (
        ssrs
          .filter((ssr) => ssr.pax.length > 0)
          .map((ssr, index) => (
            <React.Fragment key={index}>
              <Title>{ssr.ssrName}</Title>
              {sortPaxBySeat(ssr.pax, flight).map((passenger: Passenger) => (
                <Row
                  key={passenger.passengerId}
                  onClick={() => setPaxAndShowModal(passenger)}
                  data-testid="row"
                >
                  <SeatNo>{findLeg(flight, passenger)?.unitDesignator}</SeatNo>{' '}
                  <PAXContainer>
                  {passenger.name.first} {passenger.name.last}{' '}
                  {passenger.name.title}
                  </PAXContainer>
                  <SSRCodeContainer>
                    {getPaxSSRCodesByType(passenger, ssr.ssrName, flight).map((code, ssrIndex) => {
                    return (
                      <React.Fragment key={ssrIndex}>
                      <SSRCode>{code}</SSRCode>
                        <IconContainer>
                          <SsrIcon ssrs={[generateSSR(ssr.ssrName, code)]} size={30} />
                        </IconContainer>
                      </React.Fragment>
                    )
                    })}
                  </SSRCodeContainer>
                </Row>
              ))}
              <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
              >
                <GuestInfoModal
                  setShowModal={setShowModal}
                  passenger={passenger}
                />
              </IonModal>
            </React.Fragment>
          ))
      ) : (
        <Title>No Passengers With Special Services</Title>
      )}
    </StyledSsrList>
  );
}

export default SsrDetail;
