import styled from '@emotion/styled';
import { Crew, Flight } from '@models';

export interface FlightInfoCrewListProps {
  flight: Flight;
}

const StyledFlightInfoCrewList = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.primary};
  color: ${theme.colors.lightGrey};
  margin: 0;
  padding: 0;
  font-family: ${theme.fonts.worksSans};
  font-weight: 400;
  flex: 1;
  overflow: auto;
`
);

const Row = styled.div(
  ({ theme }) => `
  border-top: 0.5px solid ${theme.colors.accentGrey};
  border-bottom: 0.5px solid ${theme.colors.accentGrey};
  display: grid;
  grid-template-columns: 20% 60% 20%;
  height: 40px;
`
);

const Pic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// TODO: This is a placeholder until we get profile picture endpoint
const Circle = styled.div`
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
`;

const Name = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Rank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function FlightInfoCrewList({ flight }: FlightInfoCrewListProps) {
  /**
   * Sorts and returns an array of crew members by Captains, then First Officers, then Flight Attendants.
   * @param crewArr Array of Crew Members
   */
  function sortCrew(crewArr: Crew[]): Crew[] {
    const captains: Crew[] = crewArr.filter((crew) => crew.rank === 'CA');
    const firstOs: Crew[] = crewArr.filter((crew) => crew.rank === 'FO');
    const flightAttends: Crew[] = crewArr.filter((crew) => crew.rank === 'FA');
    return [...captains, ...firstOs, ...flightAttends];
  }

  /**
   * filters and returns an array of crew members for DH roster designators
   * @param crewArr Array of Crew Members
   */
  function filterCrew(crewArr: Crew[]): Crew[] {
    return crewArr.filter((c: Crew) => {
      const rd = c.rosterDesignator || '';
      return rd !== 'DH';
    })
  }

  const sortedCrew = flight.crew && sortCrew(filterCrew(flight.crew));

  return (
    <StyledFlightInfoCrewList>
      {sortedCrew &&
        sortedCrew.map((cMem, index) => (
          <Row key={index} data-testid="crew-member">
            <Pic>
              <Circle></Circle>
            </Pic>
            <Name>
              {cMem.firstname}&nbsp;{cMem.lastname}
            </Name>
            <Rank>{cMem.rank}</Rank>
          </Row>
        ))}
    </StyledFlightInfoCrewList>
  );
}

export default FlightInfoCrewList;
