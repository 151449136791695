import arrow from '../../../../assets/src/lib/arrow-thin-white.svg';
import styled from '@emotion/styled';
import {CREWHUB_ROUTES, Flight, Manifest, SeatMap, SSR_TYPES} from '@models';
import {IonRippleEffect, useIonRouter} from '@ionic/react';
import {
  createFlightId,
  findManifestForFlight,
  findSeatMapForFlight,
  getSSRListFromFlightAndManifest,
  setManifestCounts
} from '@utilities';
import {useCrewHubStore} from '@hooks';


export interface FlightGuestSummaryProps {
  flight?: Flight;
}


const StyledFlightGuestSummary = styled.div(
  ({theme}) => `
    background: ${theme.colors.primary};
    bottom: 25px;
    border-top: 1px solid ${theme.colors.accentGrey};
    border-bottom: 1px solid ${theme.colors.accentGrey};
    width: 100%;
  `
);

const Header = styled.h2(
  ({theme}) => `
    color: ${theme.colors.white};
    background: ${theme.colors.primary};
    font-family: ${theme.fonts.secondary};
    display: block;
    margin: 0 0 8px 0;
    font-size: ${theme.fontSizes.medium};
  `
);

const HeaderNoMargin = styled.h2(
  ({theme}) => `
    color: ${theme.colors.white};
    background: ${theme.colors.primary};
    font-family: ${theme.fonts.secondary};
    display: block;
    margin: 0;
    font-size: ${theme.fontSizes.medium};
  `
);

const TopDataContainer = styled.div`
  padding: 14px 16px;
`;

const DataContainer = styled.div(
  ({theme}) => `
    padding: 14px 16px;
    border-top: 1px solid ${theme.colors.accentGrey};
  `
);

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Data = styled.div(
  ({theme}) => `
    color: ${theme.colors.lightGrey};
    font-family: ${theme.fonts.worksSans};
    display: flex;
    align-items: center
  `
);

const Icon = styled.img(
  ({theme}) => `
    margin-left: 5px;
    margin-right: 2px;
    width: 50px;
    height: 50px;
    @media(max-width: ${theme.breakpoints.medium}) {
      width: 25px;
      height: 25px;
    }
    @media(max-width: ${theme.breakpoints.small}) {
      width: 20px;
      height: 20px;
    }
  `
);

const DataItem = styled.span(
  ({theme}) => `
    margin-right: 8px;
    font-size: ${theme.fontSizes.small}
  `
);

export function FlightGuestSummary({flight}: FlightGuestSummaryProps) {
  const router = useIonRouter();
  const store = useCrewHubStore();

  const manifest: Manifest | undefined =
    store.manifests &&
    flight &&
    findManifestForFlight(store.manifests, flight);

  const seatMap: SeatMap | undefined =
    store.seatMaps &&
    flight &&
    findSeatMapForFlight(store.seatMaps, flight);

  const manifestCounts = manifest && flight && setManifestCounts(manifest, flight);

  const routeChange = (path: CREWHUB_ROUTES) => {
    if (path === CREWHUB_ROUTES.SEATMAP && !manifest) {
      return;
    }
    const flightId = flight ? createFlightId(flight) : '';
    const pathWithId = `${path}/${flightId}`;
    router.push(pathWithId);
  };

  const ssrList =
    flight &&
    manifest &&
    getSSRListFromFlightAndManifest(flight, manifest);

  const findSsr = (ssrName: string) => {
    const correctSsr = ssrList?.find((ssr) => {
      return ssrName === ssr.ssrName;
    });
    return !ssrList
      ? undefined
      : ssrList && !correctSsr
        ? 0
        : correctSsr?.pax.length;
  };

  return (
    <StyledFlightGuestSummary>
      <TopDataContainer>
        <SummaryContainer
          onClick={() => routeChange(CREWHUB_ROUTES.GUEST_LIST)}
        >
          <HeaderNoMargin>Guest List</HeaderNoMargin>
          <div>
            <Icon src={arrow} className="arrow-icon"/>
          </div>
          <IonRippleEffect></IonRippleEffect>
        </SummaryContainer>
      </TopDataContainer>
      <DataContainer>
        <SummaryContainer onClick={() => routeChange(CREWHUB_ROUTES.SEATMAP)}>
          <div>
            <Header>Seats</Header>
            <Data>
              <DataItem>Total Seats: {manifestCounts?.totalSeats}</DataItem>
              <DataItem>Booked: {manifestCounts?.booked}</DataItem>
              <DataItem>Boarded: {manifestCounts?.boarded}</DataItem>
              <DataItem>Thru: {manifestCounts?.thru}</DataItem>
            </Data>
          </div>
          <div>
            {seatMap?.seats &&
              Array.isArray(seatMap?.seats) &&
              seatMap?.seats.length > 0 && (
                <Icon src={arrow} className="arrow-icon"/>
              )}
          </div>
          <IonRippleEffect></IonRippleEffect>
        </SummaryContainer>
      </DataContainer>
      <DataContainer>
        <SummaryContainer
          onClick={() => routeChange(CREWHUB_ROUTES.SSR_DETAILS)}
        >
          <div>
            <Header>Special Services</Header>
            <Data>
              <DataItem>Wheelchair: {findSsr(SSR_TYPES.WHEELCHAIR)}</DataItem>
              <DataItem>Aisle Chair: {findSsr(SSR_TYPES.AISLECHAIR)}</DataItem>
            </Data>
          </div>
          <div>
            <Icon src={arrow} className="arrow-icon"/>
          </div>
          <IonRippleEffect></IonRippleEffect>
        </SummaryContainer>
      </DataContainer>
    </StyledFlightGuestSummary>
  );
}

export default FlightGuestSummary;
