import { gql } from '@apollo/client';

export const manifestQuery = gql`
  query manifest(
    $flightNum: String!
    $flightDate: ISO8601DateTime!
    $origin: String!
    $destination: String!
  ) {
    manifest(
      flightNum: $flightNum
      flightDate: $flightDate
      origin: $origin
      destination: $destination
    ) {
      errorCode
      errorMessage
      manifest
    }
  }
`;
