import { gql } from '@apollo/client';

export const seatMapUpdatesSubscription = gql`
  subscription seatMapUpdates($seatMapName: String!) {
    seatMapUpdates(seatMapName: $seatMapName) {
      seatMapName
      seatMap {
        seatMapReference
        market {
          departureDate
          origin
          destination
          identifier {
            identifier
          }
        }
        seats {
          allowedSsrs {
            code
            name
          }
          assignable
          availability
          designator
          group
          text
          type
          unitKey
          x
          y
          zone
          properties {
            code
            value
          }
        }
      }
    }
  }
`;
