import { IonContent, IonPage } from '@ionic/react';

import {
  FlightInfoCrewList,
  FlightInfo,
  FlightGuestSummary,
  PageHeader
} from '@components';
import { useCrewHubStore } from '@hooks';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router-dom';
import { findSelectedFlightFromState } from '@utilities';
import { Flight } from '@models';

const FlightDetailsPageStyling = styled.div`
  display: flex;
  flex-flow: column;
  height: calc(100vh - 140px);
`;

export interface FlightDetailsPageProps
  extends RouteComponentProps<{
    flightId: string;
  }> {
    networkIsConnected: boolean | undefined;
  }

export function FlightDetailsPage( {match}: FlightDetailsPageProps ) {
  const state = useCrewHubStore();


  const flightId = match.params.flightId;
  const flight: Flight | undefined =
    state.flights && findSelectedFlightFromState(state.flights, flightId);
  return (
    <IonPage>
      <PageHeader
        showFlightNumber={true}
        flightNumber={flight?.flightNumber}
        showLogo={false}
      />
      <IonContent fullscreen>
        {flight && (
          <FlightDetailsPageStyling>
            <FlightInfo flight={flight} />
            <FlightInfoCrewList flight={flight} />
            <FlightGuestSummary flight={flight} />
          </FlightDetailsPageStyling>
        )}
      </IonContent>
    </IonPage>
  );
}
