import { AuthConfig } from '@models';
import { createContext, useContext, useEffect, useState } from 'react';
import { MsAuthPlugin } from '@programmer-jeff/capacitor-plugin-msauth';
import { useStorage } from '../storage/storage';
import jwt_decode from 'jwt-decode';

const MsalContext = createContext<{
  initialize: () => Promise<void>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  acquireTokenSilent: (scopes: string[]) => Promise<string>;
  username: string;

}>({
  initialize: () => {
    throw new Error('Method not implemented');
  },
  login: () => {
    throw new Error('Method not implemented');
  },
  logout: () => {
    throw new Error('Method not implemented');
  },
  acquireTokenSilent: () => {
    throw new Error('Method not implemented');
  },
  username: ''
});
export interface MsalProps {
  children: React.ReactNode;
  config: AuthConfig;
}

export const MsalProvider: React.FC<MsalProps> = (props: MsalProps) => {
  const [token, setToken] = useState<string>('');
  const [username, setUsername] = useState<string>('')
  const storage = useStorage();

  useEffect(() => {
    if (token) {
      const { name } = jwt_decode<{ name: string }>(
        token
      );
      setUsername(name);
    }
  }, [token])

  const initialize = async (): Promise<void> => {
    await MsAuthPlugin.initialize({
      clientId: props.config.clientId,
      tenant: props.config.tenantId,
      scopes: props.config.scopes,
      loginMethod: props.config.loginMethod,
      authorityType: 'AAD',
      authorityUrl:
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac',
      knownAuthorities: [
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac'
      ]
    });
  };

  const login = async (): Promise<void> => {
    await MsAuthPlugin.login({
      clientId: props.config.clientId,
      tenant: props.config.tenantId,
      scopes: props.config.scopes,
      loginMethod: props.config.loginMethod,
      authorityType: 'AAD',
      authorityUrl:
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac',
      knownAuthorities: [
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac'
      ]
    });
  };

  const logout = async (): Promise<void> => {
    storage.remove('token');
    setToken('');
    setUsername('');

    await MsAuthPlugin.logout({
      clientId: props.config.clientId,
      tenant: props.config.tenantId,
      loginMethod: props.config.loginMethod,
      authorityType: 'AAD',
      authorityUrl:
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac',
      knownAuthorities: [
        'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac'
      ]
    });
  };

  const acquireTokenSilent = async (scopes: string[]): Promise<string> => {    
    try {      
      const result = await MsAuthPlugin.acquireTokenSilent({
        clientId: props.config.clientId,
        tenant: props.config.tenantId,
        scopes: scopes,
        authorityType: 'AAD',
        authorityUrl:
          'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac',
        knownAuthorities: [
          'https://login.microsoftonline.com/05fbf8f1-b0b0-4267-b065-30f37c6a5aac'
        ]
      });

      const { exp, iat, name } = jwt_decode<{ exp: string; iat: string, name: string }>(
        result.accessToken
      );    
      setUsername(name);

      return result.accessToken;
    } catch {
      throw new Error('Not signed in. Sign in via interactive login before requesting access token.');
    }
  }

  return (
    <MsalContext.Provider
      value={{
        initialize,
        login,
        logout,
        acquireTokenSilent,
        username,
      }}
      {...props}
    />
  );
};

export const useSession = () => {
  const { initialize, login, logout, acquireTokenSilent, username } = useContext(MsalContext);

  return {
    initialize,
    login,
    logout,
    acquireTokenSilent,
    username,
  };
};
