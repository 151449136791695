import styled from '@emotion/styled';
import { ALL_SSR_CODES, Flight, Leg, Passenger } from '@models';
import { format } from 'date-fns';
import {IonContent, IonIcon} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { useCrewHubStore } from '@hooks';
import {
  compareFlightToUrl,
  findLeg,
  findSegment,
  findSelectedFlightFromState
} from '@utilities';
import { useParams } from 'react-router-dom';

export interface GuestInfoModalProps {
  setShowModal: (showModal: boolean) => void;
  passenger: Passenger | undefined;
}

const StyledGuestInfoModal = styled.div(
  ({ theme }) => `
  color: ${theme.colors.lightGrey};
  font-family: ${theme.fonts.worksSans}
  font-weight: 400;
  background-color: ${theme.colors.primary}
`
);

const SeatNumberBorderCircle = styled.div(
  ({ theme }) => `
    background: none;
    border: 2px solid rgba(128, 128, 128, 0.5);
    width: 80px;
    height: 85px;
    margin-bottom: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  `
);

const SeatNumber = styled.div(
  ({ theme }) => `
    padding-top: 20px;
    font-size: 1.5rem;
    color: ${theme.colors.accentGreyTwo};
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
`
);

const Title = styled.h2(
  ({ theme }) => `
  border-top: 0.5px solid ${theme.colors.accentGrey};
  padding: 2% ${theme.padding.leftEdge};
  color: ${theme.colors.white};
  background: ${theme.colors.darkBlue};
  font-family: ${theme.fonts.secondary};
  display: block;
  margin: 0 0 0 0;
  font-size: ${theme.fontSizes.medium};
  `
);

const Row = styled.div(
  ({ theme }) => `
  border-top: 0.5px solid ${theme.colors.accentGrey};
  display: grid;
  grid-template-columns: 1fr;
  min-height: 40px;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: ${theme.padding.leftEdge};
`
);

const AirportHeader = styled.div`
  text-align: center;
  margin-bottom: 14px;
`;

const ModalFooter = styled.div(
  ({ theme }) => `
  height: 100vh;
  background-color: ${theme.colors.primary}`
);

const SSRContainer = styled.div`
  height: 50vh;
`;

const ScrollContainer = styled(IonContent)`
  height: 225px;
  overflow-y: scroll;
  --color: #fff;
  @media(max-width: 430px){
    height: 325px;
  }
`;


const findAirports = (legs: Leg[]) => {
  const map = new Map();
  if (legs.length > 1) {
    legs.forEach((leg, index) => {
      if (!leg.market) {
        return;
      }
      map.set(`origin-${index}`, leg.market?.origin);
      map.set(`destination-${index}`, leg.market?.destination);
    });
  }
  return map;
};

function checkBreezeThru(
  seatChangeRequired: boolean | undefined,
  isBreezeThru: boolean | undefined
) {
  if (!isBreezeThru) {
    return { display: 'none' };
  }

  const baseStyles = {
    marginLeft: '-1px',
    marginTop: '4px',
    height: '0',
    width: '0',
    borderTop: '30px solid transparent',
    borderLeft: '30px solid white',
    gridColumnStart: '1',
    gridRowStart: '3',
    gridRowEnd: '5',
    display: 'block'
  };

  if (!seatChangeRequired) {
    return baseStyles;
  } else {
    return {
      ...baseStyles,
      borderLeft: '30px solid #FF527B'
    };
  }
}

export function GuestInfoModal({
  setShowModal,
  passenger,
}: GuestInfoModalProps) {
  const state = useCrewHubStore();
  const { flightId } = useParams<{ flightId?: string }>();

  const flight: Flight | undefined =
    state.flights &&
    state.flights.find((flight: Flight) => {
      return compareFlightToUrl(flight, flightId);
    });
  if (!passenger) {
    // we don't necessarily want to return any dom element if passenger is falsy
    return <></>;
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const segment = findSegment(flight, passenger);
  const leg = findLeg(flight, passenger);

  if (!segment || !leg) {
    // we don't necessarily want to return any dom element if segment or leg is falsy
    return <></>;
  }

  const seatChangeRequired = segment.seatChangeRequired;
  const isBreezeThru = segment.isBreezeThru;
  const fName = passenger.name.first;
  const lName = passenger.name.last;
  const seatNums: (string | null)[] = segment.legs.map((leg) => {
    return leg.unitDesignator;
  });
  const ssrs = leg.ssrs.filter((ssr) => ALL_SSR_CODES.includes(ssr.code));
  const bday =
    passenger.passengerInformation.dateOfBirth &&
    format(new Date(passenger.passengerInformation.dateOfBirth), 'MM/dd');
  const airports = findAirports(segment.legs);
  const gridColumns = seatChangeRequired
    ? seatNums.length
    : seatNums.length - 1;

  const SeatNumberWrapper = styled.div`
    display: grid;
    place-items: center;
    grid-template-columns: repeat(${gridColumns}, 1fr);
    margin-bottom: ${gridColumns > 1 ? '0' : '14px'};
  `;


  return (
    <StyledGuestInfoModal>
      <IonIcon
        icon={closeCircleOutline}
        onClick={closeModal}
        style={{
          padding: '10px',
          margin: '0',
          height: '40px',
          width: '40px'
        }}
      ></IonIcon>
      <SeatNumberWrapper>
        {seatNums.map((seatNum: string | null, index) => {
          // to not show two squares if passenger has breeze through with no seat change
          if (index !== 0 && !seatChangeRequired) {
            return <div key={index}></div>;
          } else {
            return (
              <div key={index}>
                <SeatNumberBorderCircle>
                  <SeatNumber>{seatNum && `${seatNum}`}</SeatNumber>
                  <div
                    style={{
                      ...checkBreezeThru(seatChangeRequired, isBreezeThru)
                    }}
                    data-testid="breezeThru-triangle"
                  ></div>
                </SeatNumberBorderCircle>
                {airports.size > 0 && seatChangeRequired && (
                  <AirportHeader data-testid="airport-header">
                    {airports.get(`origin-${index}`)}-
                    {airports.get(`destination-${index}`)}
                  </AirportHeader>
                )}
              </div>
            );
          }
        })}
      </SeatNumberWrapper>
      <Title>Guest Details</Title>
      <Row>{fName && lName && `Name: ${fName} ${lName}`}</Row>
      <Row>{bday && `Birthday: ${bday}`}</Row>
      <Title>Special Services</Title>
      <SSRContainer>
        <ScrollContainer>
        {ssrs.length > 0 &&
          ssrs.map((ssr, index) => {
            return (
              <Row key={`${passenger.passengerId}-SRR-${index}`}>
                {ssr.code}: {ssr.name}
              </Row>
            );
          })}
        </ScrollContainer>
      </SSRContainer>
      <ModalFooter />
    </StyledGuestInfoModal>
  );
}

export default GuestInfoModal;
