import {
  AircrafType,
  Crew,
  Flight,
  FlightStatus,
  Leg,
  LiftStatus,
  Manifest,
  Market,
  Passenger,
  ScheduleFlight,
  Segment,
  SSR,
  SSR_LIST,
  UnitInformation,
  WeightAndBalance
} from '@models';
import { compareAsc, sub } from 'date-fns';
import { cloneDeep } from 'lodash';
import { CrewHubTheme } from '@themes';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { convertTimeToLocal } from '../time-utils/time-utils';

export function addReportTime(flight: Flight): string {
  return flight.stdLocal
    ? `${sub(new Date(flight.stdLocal), { minutes: 45 })}`
    : '';
}

export function compareFlightData(flight: Flight, market: Market): boolean {
  if (!flight?.stdLocal || !market?.departureDate) {
    return false;
  }
  return (
    flight.scheduledDepartureAirport === market.origin &&
    flight.scheduledArrivalAirport === market.destination &&
    flight.flightNumber === market.identifier.identifier &&
    flight.stdLocal.substring(0, 10) === market.departureDate.substring(0, 10)
  );
}

export function compareScheduledFlightData(flight: ScheduleFlight, market: Market): boolean {
  if (!flight?.start || !market?.departureDate) {
    return false;
  }
  return (
    flight.startAirportCode === market.origin &&
    flight.endAirportCode === market.destination &&
    extractFlightNum(flight.activityCode) === market.identifier.identifier &&
    convertTimeToLocal(flight.start, flight.startLocalTimeDiff).substring(0, 10) === market.departureDate.substring(0, 10)
  );
}

export function findLeg(
  flight: Flight | undefined,
  passenger: Passenger | undefined
): Leg | undefined {
  if (!flight || !passenger) {
    return undefined;
  }

  let correctLeg = undefined;
  passenger.services.forEach((service) => {
    service?.segments.forEach((segment) => {
      segment?.legs.forEach((leg) => {
        if (
          leg.market?.identifier.identifier === flight.flightNumber &&
          leg.market?.origin === flight.scheduledDepartureAirport &&
          leg.market?.destination === flight.scheduledArrivalAirport
        ) {
          correctLeg = leg;
        }
      });
    });
  });
  return correctLeg;
}

export function findSegment(
  flight: Flight | undefined,
  passenger: Passenger | undefined
): Segment | undefined {
  if (!flight || !passenger) {
    return undefined;
  }

  let correctSegment = undefined;
  passenger.services.forEach((service) => {
    service?.segments.forEach((segment) => {
      segment?.legs.forEach((leg) => {
        if (
          leg.market?.identifier.identifier === flight.flightNumber &&
          leg.market?.origin === flight.scheduledDepartureAirport &&
          leg.market?.destination === flight.scheduledArrivalAirport
        ) {
          correctSegment = segment;
        }
      });
    });
  });
  return correctSegment;
}

export function getSpecialServicePax(
  flight: Flight,
  paxDetails: Passenger[],
  ssrCodes: string[],
  ssrName: string
): {
  ssrName: string;
  pax: Passenger[];
} {
  if (!paxDetails || !Array.isArray(paxDetails)) {
    return {
      ssrName: '',
      pax: []
    };
  }

  const relatedPaxDetails = paxDetails.filter((passenger: Passenger) => {
    const leg = findLeg(flight, passenger);
    return leg?.ssrs.some((ssr: SSR) => {
      return ssrCodes.includes(ssr.code);
    });
  });

  return {
    ssrName: ssrName,
    pax: relatedPaxDetails
  };
}

export function convertFlightStatus(flightStatus: FlightStatus) {
  if (
    flightStatus === 'Scheduled' ||
    flightStatus === 'Taxing' ||
    flightStatus === 'Airborne'
  ) {
    return 'On Time';
  }
  if (flightStatus === 'Delayed') {
    return 'Delayed';
  }
  if (flightStatus === 'Canceled') {
    return 'Canceled';
  }
  if (flightStatus === 'Completed') {
    return 'Completed';
  }
  return;
}

export function convertAircraftType(aircraftType: AircrafType | string) {
  switch (aircraftType) {
    case '223':
      return 'A220';
    case 'E90':
      return 'E190';
    case 'E95':
      return 'E195';
    default:
      return aircraftType;
  }
}

export function colorChanger(flightStatus: FlightStatus) {
  return flightStatus === FlightStatus.OnTime ||
    flightStatus === FlightStatus.Airborne ||
    flightStatus === FlightStatus.Taxing
    ? CrewHubTheme.colors.green
    : flightStatus === FlightStatus.Delayed
    ? CrewHubTheme.colors.purple
    : flightStatus === FlightStatus.Canceled
    ? CrewHubTheme.colors.lightRed
    : flightStatus === FlightStatus.Completed
    ? CrewHubTheme.colors.lightBlue
    : CrewHubTheme.colors.darkBlue;
}

export function addPaxToSeats(
  seats: UnitInformation[],
  passengers: Passenger[] | undefined,
  flight: Flight
): UnitInformation[] {
  if (passengers === undefined || passengers === null) {
    return seats;
  }
  if (seats === undefined || seats === null) {
    console.error('seats undefined');
    return [];
  }
  const newSeats = cloneDeep(seats);
  newSeats.forEach((seat: UnitInformation) => {
    passengers.forEach((pax) => {
      // handle BreezeThru (TM)
      const leg = findLeg(flight, pax);

      if (leg?.unitDesignator === seat.designator) {
        seat.passenger = pax;
      }
    });
  });
  return newSeats;
}

export function clientFactory(token = '', uri: string, delay = 500, maxAttempts = 3) {
  const httpLink = new HttpLink({
    uri,
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  const retryLink = new RetryLink({
    delay: {
      initial: delay
    },
    attempts: {
      max: maxAttempts
    }
  });
  const apolloLinks = ApolloLink.from([
    retryLink,
    httpLink
  ])
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: apolloLinks
  });
}

export function seatCompare(seatA: string, seatB: string): boolean {
  // num regex
  const numReg = /\d+/g;
  const charReg = /[a-zA-Z]+/g;

  // match the number portion of the seat number
  const seatANum = seatA.match(numReg);
  const seatAChar = seatA.match(charReg);

  const seatBNum = seatB.match(numReg);
  const seatBChar = seatB.match(charReg);

  if (!seatANum || !seatAChar || !seatBNum || !seatBChar) {
    return false;
  }

  // if the numbers are equal, check the value of the letter
  if (Number(seatANum) === Number(seatBNum)) {
    return seatAChar > seatBChar;
  } else {
    return Number(seatANum) > Number(seatBNum);
  }
}

export const sortPaxBySeat = (
  paxArray: Passenger[],
  flight?: Flight
): Passenger[] => {
  const paxNoSeats = paxArray.filter((pax: Passenger) => {
    const leg = findLeg(flight, pax);
    const seat = leg?.unitDesignator;
    return !seat;
  });

  const paxWithSeats = paxArray.filter((pax: Passenger) => {
    return !paxNoSeats.includes(pax);
  });

  const sorted = paxWithSeats.sort((a: Passenger, b: Passenger) => {
    if (!flight) {
      return -1;
    }
    const aLeg = findLeg(flight, a);
    const aSeat = aLeg?.unitDesignator;

    const bLeg = findLeg(flight, b);
    const bSeat = bLeg?.unitDesignator;

    if (aSeat && bSeat && seatCompare(aSeat, bSeat)) {
      return 1;
    }
    return -1;
  });
  return [...sorted, ...paxNoSeats];
};

export function createFlightId(flight: Flight): string {
  return `${flight.uniqueId}*${flight.std}*${flight.actualDepartureAirport}*${flight.actualArrivalAirport}`;
}

export function compareFlightToUrl(
  flight: Flight,
  flightUrlId: string | undefined
): boolean {
  const paramsArray = flightUrlId?.split('*');
  let id = '';
  let std = '';
  let arrivalAirport = '';
  let departAirport = '';
  if (paramsArray) {
    id = paramsArray[0];
    std = paramsArray[1];
    departAirport = paramsArray[2];
    arrivalAirport = paramsArray[3];
  }

  return (
    flight.uniqueId === id &&
    flight.std?.substring(0, 19) === std.substring(0, 19) &&
    flight.actualArrivalAirport === arrivalAirport &&
    flight.actualDepartureAirport === departAirport
  );
}
export function setManifestCounts(manifest: Manifest, flight: Flight) {
  return {
    totalSeats: manifest?.checkInLid || 0,
    boarded: manifest?.passengerCounts?.totalOnBoard || 0,
    booked: manifest?.passengerCounts?.manifested,
    thru: thruBoarded(manifest, flight),
  }
}

// FIXME - might make sense to move this to the backend?
export function thruBoarded(manifest: Manifest, flight: Flight) {
  let boarded = 0;

  manifest.passengerList.forEach((passenger: Passenger) => {
    // determine if thru pax
    const seg = findSegment(flight, passenger);

    if (seg?.isBreezeThru) {
      // determine if boarded
      const leg = findLeg(flight, passenger);

      if (leg?.liftStatus === LiftStatus.BOARDED) {
        boarded += 1;
      }
    }
  })

  return boarded;
}

export function getSSRListFromFlightAndManifest(
  flight: Flight,
  manifest: Manifest
) {
  return SSR_LIST.map((ssrType) => {
    return getSpecialServicePax(
      flight,
      manifest.passengerList,
      ssrType.codes,
      ssrType.type
    );
  });
}

export function findSelectedFlightFromState(
  flights: Flight[],
  flightId: string | undefined
): Flight | undefined {
  return flights.find((flight: Flight) => {
    return compareFlightToUrl(flight, flightId);
  });
}

export function getWeightBalanceFromState(
  weightBalance: WeightAndBalance
): WeightAndBalance | undefined {
  if (weightBalance.zones.length !== 0) {
    return weightBalance;
  }
  return;
}

export const getPaxSSRCodesByType = (pax: Passenger, ssrType: string, flight: Flight): string[] => {
  const leg = findLeg(flight, pax);
  const codes =  !leg ? [] : leg.ssrs.map((ssr) => {
    return ssr.code;
  });
  const group = SSR_LIST.find((ssrGroup) => {
    return ssrGroup.type.includes(ssrType);
  });
  return !group ? [] : codes.filter((code) => {
    return group.codes.includes(code);
  });
}

// we may want to do a fuzzy comparison or figure out a way to grab the signed in user's employee id easily to do a better comparison
export const userInCrewList = (flightCrew: Crew[] | undefined, username: string): boolean => {
  if(!username || flightCrew?.length === 0 || !flightCrew){
    // it would be better to keep the flight in the user's list than remove it erroneously.
    return true
  }
  return flightCrew.some((member: Crew) => {
    const crewName = [member.firstname, member.lastname].join(' ');
    return crewName === username;
  })
}

// remove the leading 'MX' on the flight number
export const extractFlightNum = (flightNum: string | undefined)  => flightNum ? flightNum.slice(2) : '';

