import {gql} from "@apollo/client";

export const flightQuery = gql`
  query flight($uniqueId: String!) {
    flight(uniqueId: $uniqueId) {
      flight {
        uniqueId
        status
        tailNumber
        aircraftType
        flightNumber
        flightDate
        scheduledDepartureAirport
        scheduledArrivalAirport
        std
        stdLocal
        sta
        staLocal
        offsetDa
        offsetAa
        actualDepartureAirport
        actualArrivalAirport
        departureGate
        arrivalGate
        estimatedTimes {
          estimatedBlockOff
          estimatedTakeOff
          estimatedTouchDown
          estimatedBlockOn
        }
        crew {
          firstname
          lastname
          number
          rank
          rosterDesignator
        }
      }
      errorCode
      errorMessage
    }
  }
`;
