import { screen } from '@testing-library/react';
import { gql } from '@apollo/client';
import {
  AircrafType,
  Flight,
  FlightStatus,
  MockCrew,
  MockManifest
} from '@models';

export const getByTextWithMarkup = (text: string) => {
  screen.getByText((content, node: any) => {
    const hasText = (node: HTMLElement) => node.textContent === text;
    const childrenDontHaveText = Array.from(node.children).every(
      (child) => !hasText(child as HTMLElement)
    );
    return hasText(node) && childrenDontHaveText;
  });
};

export const buildSeatNumsArr = (numRows: number) => {
  const arr: string[] = [];
  for (let i = 0; i < numRows; i++) {
    ['A', 'C', 'D', 'F'].forEach((l) => {
      arr.push(`${i + 1}${l}`);
    });
  }
  return arr;
};

// stolen from github because I am lazy :)
export const shuffleArr = (arr: any[]) => {
  const shuffledArr = [...arr];
  let currIndex = shuffledArr.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currIndex);
    currIndex--;

    // And swap it with the current element.
    [shuffledArr[currIndex], shuffledArr[randomIndex]] = [
      shuffledArr[randomIndex],
      shuffledArr[currIndex]
    ];
  }

  return shuffledArr;
};

// This is here because it is a circular depency to import into utilities lib from graphql. models, etc
export const testFlightUpdatesSubscription = gql`
  subscription FlightUpdates($flightName: String!) {
    flightUpdates(flightName: $flightName) {
      actualArrivalAirport
      actualDepartureAirport
      aircraftType
      arrivalGate
      departureGate
      crew {
        firstname
        lastname
        number
        rank
      }
      estimatedTimes {
        estimatedBlockOff
      }
      flightDate
      flightNumber
      flightName
      offsetDa
      offsetAa
      scheduledArrivalAirport
      scheduledDepartureAirport
      sta
      staLocal
      status
      std
      stdLocal
      tailNumber
      uniqueId
    }
  }
`;
