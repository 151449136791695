import { useEffect, useState } from 'react';
import {
  DATETIME_FORMAT,
  ScheduleFlight,
  SeatMap,
  SeatMapsResponse
} from '@models';
import { format } from 'date-fns';
import { useSession } from '../msal/msal';
import { seatMapNavitaireApi } from '@services';
import { CrewHubActionTypes } from '../crew-hub-store/crew-hub-store-actions';
import {
  useCrewHubStore,
  useCrewHubStoreDispatch
} from '../crew-hub-store/crew-hub-store';
import {ApolloError} from "@apollo/client";

const timeStamp = () => format(new Date(), DATETIME_FORMAT);

export function useSeatMap(
  baseUrl: string,
  scope: string
): {
  loading: boolean;
  error?: ApolloError;
} {
  const [flightSchedule, setFlightSchedule] = useState<ScheduleFlight[]>([]);
  const [loadingSeatMap, setLoadingSeatMap] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const store = useCrewHubStore();
  const dispatch = useCrewHubStoreDispatch();
  const { acquireTokenSilent } = useSession();
  const [token, setToken] = useState<string>('');
  const schedule = store.schedule;
  const online = store.isOnline;

  acquireTokenSilent([scope]).then((accessToken) => {
    setToken(accessToken);
  });

  useEffect(() => {
    if (schedule && schedule.length !== 0 && online) {
      setFlightSchedule(store.schedule);
    }
  }, [schedule, online]);

  useEffect(() => {
    if (flightSchedule.length > 0 && token && online) {
      const fetchData = async () => {
        setLoadingSeatMap(true);
        try {
          const seatmapsResponse: SeatMapsResponse = await seatMapNavitaireApi(
            flightSchedule,
            baseUrl,
            token
          );
          dispatch({
            type: CrewHubActionTypes.setSeatMapsResponse,
            payload: seatmapsResponse as SeatMapsResponse
          });
          setSeatMapsInStore(seatmapsResponse.seatMaps);
          setError(undefined);
          setLoadingSeatMap(false);
          if(seatmapsResponse.seatMaps.length === 0){
            dispatch({
              type: CrewHubActionTypes.setLoading,
              payload: false
            })
          }
        } catch (e: any) {
          console.error( e);
          setError(e as ApolloError)
          setSeatMapsInStore(store?.seatMaps || []);
          setLoadingSeatMap(false);
          dispatch({
            type: CrewHubActionTypes.setLoading,
            payload: false
          })
        }
      };

      fetchData().catch(console.error);
    }
  }, [flightSchedule, online]);

  const setSeatMapsInStore = (seatMaps: SeatMap[]) => {
    const responseToStore: SeatMapsResponse = {
      responseCode: error ? '!200' : '200',
      errorMessage: error ? error.message : '',
      time: timeStamp(),
      seatMaps: seatMaps
    }

    dispatch({
      type: CrewHubActionTypes.setSeatMapsResponse,
      payload: responseToStore as SeatMapsResponse
    });
  }

  return { loading: loadingSeatMap, error };
}
