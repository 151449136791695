import { Manifest, SeatMap, Market } from '../nav-models';
import { MockPassengerNoSSRS, MockPax } from './mock-passenger-data';
import { unitsWithPax } from './mock-seatmap-data';

//used alongside flight from mock-flight-data.tsx line 381 (MockFlightGuestWithSummary)
//To-Do: refactor to make this more clear^

export const MockMarketCHSSFO: Market = {
  destination: 'CHS',
  origin: 'SFO',
  identifier: {
    identifier: '101'
  },
  departureDate: '2022-06-29T00:00:00+00:00'
}

export const MockFlightGuestWithSummaryManifest: Manifest = {
  checkInLid: 123,
  market: MockMarketCHSSFO,
  passengerList: MockPax
};

export const MockFlightGuestWithSummaryNoSsrsManifest: Manifest = {
  checkInLid: 123,
  market: MockMarketCHSSFO,
  passengerList: [MockPassengerNoSSRS]
};

export const MockFlightGuestWithSummaryUndefPaxManifest: Manifest = {
  checkInLid: 123,
  market: MockMarketCHSSFO,
  passengerList: []
};

export const MockManifest: Manifest = MockFlightGuestWithSummaryManifest;

export const MockSeatMap: SeatMap = {
  seatMapReference: '',
  seats: unitsWithPax,
  market: MockMarketCHSSFO
};

export const MockManifestNoSsrs: Manifest = MockFlightGuestWithSummaryNoSsrsManifest;

export const MockSeatMapNoSsrs: SeatMap = {
  seatMapReference: '',
  seats: unitsWithPax,
  market: MockMarketCHSSFO
};

export const MockManifestUndefinedPax: Manifest = MockFlightGuestWithSummaryUndefPaxManifest;

export const MockSeatMapUndefinedPax: SeatMap = {
  seatMapReference: '',
  seats: [],
  market: MockMarketCHSSFO
};

export const MockFlightGuestWithSummaryManifestUnsortedSeats: Manifest = {
  checkInLid: 126,
  market: {
    origin: 'SFO',
    destination: 'CHS',
    departureDate: '2022-06-29T00:00:00',
    identifier: {
      identifier: '101'
    }
  },
  passengerList: [
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96215,
      name: {
        first: 'Prince',
        last: 'Andrew',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '1A'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96217,
      name: {
        first: 'Pierre',
        last: 'Buzukov',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '2F'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96216,
      name: {
        first: 'Boris',
        last: 'Drubetskoy',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '20F'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96226,
      name: {
        first: 'Emperor',
        last: 'Alexander',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '10A'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96218,
      name: {
        first: 'Helene',
        last: 'Kuragina',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96219,
      name: {
        first: 'Mary',
        last: 'Bolkonski',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96225,
      name: {
        first: 'Napoleon',
        last: 'Bonaparte',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '1B'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96224,
      name: {
        first: 'Anatole',
        last: 'Kuragin',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '2D'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96227,
      name: {
        first: 'Natasha',
        last: 'Rostova',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '10B'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96228,
      name: {
        first: 'Dorian',
        last: 'Gray',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '20D'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96229,
      name: {
        first: 'Nicholas',
        last: 'Rostov',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '22D'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96213,
      name: {
        first: 'Mikhail',
        last: 'Kutuzov',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '11A'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      recordLocator: 'H5JY5Z',
      passengerId: 96212,
      name: {
        first: 'Leo',
        last: 'Tolstoy',
        title: null
      },
      infant: null,
      passengerInformation: {
        dateOfBirth: '1995-05-27T00:00:00'
      },
      services: [
        {
          segments: [
            {
              segmentKey:
                'TVh_IDIxMX4gfn5QSVR_MDgvMDgvMjAyMiAwMDowMH5DSFN_MDEvMDEvMTgwMCAwMDowMH5_',
              legs: [
                {
                  market: {
                    identifier: { identifier: '101' },
                    origin: 'SFO',
                    destination: 'CHS'
                  },
                  ssrs: [],
                  unitDesignator: '20A'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export const MockManifestUnsortedSeats: Manifest = MockFlightGuestWithSummaryManifestUnsortedSeats;

export const MockSeatMapUnsortedSeats: SeatMap = {
  seatMapReference: '',
  seats: unitsWithPax,
  market: MockMarketCHSSFO
};
