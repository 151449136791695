import styled from '@emotion/styled';

/* eslint-disable-next-line */
export interface ErrorFallbackProps {}

const StyledErrorFallback = styled.div`
  color: pink;
`;

export function ErrorFallback(props: ErrorFallbackProps) {
  return (
    <StyledErrorFallback>
      <h1>Welcome to ErrorFallback!</h1>
    </StyledErrorFallback>
  );
}

export default ErrorFallback;
