import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { useStorage } from '../storage/storage';
import {crewHubStateReducer} from "../crew-hub-store/crew-hub-store-reducer";
import {CrewHubAction, CrewHubActionTypes} from "../crew-hub-store/crew-hub-store-actions";
import {emptyCrewHubState} from "@models/src/lib/mock-data/empty-crew-hub-state";
import {CrewHubState} from "@models/src/lib/crewhub-state";

export interface CrewHubStoreProviderProps {
  children: React.ReactNode;
  initializeFromStorage: boolean;
}

export function CrewHubStoreProvider(props: CrewHubStoreProviderProps) {
  const [state, dispatch] = useReducer(crewHubStateReducer, emptyCrewHubState);
  const storage = useStorage();
  const isOnline = state.isOnline;

  useEffect(() => {
    if (
      state?.scheduleResponse?.responseCode === '200' ||
      state?.flightsResponse?.responseCode === '200' ||
      state?.manifestsResponse?.responseCode === '200' ||
      state?.seatMapsResponse?.responseCode === '200'
    ) {
      const fieldsToExclude = ['flightSubscriptions', 'manifestSubscriptions', 'seatMapSubscriptions'];
      const filteredState = {...state};
      fieldsToExclude.forEach((field) => delete filteredState[field]);
      storage?.set('state', filteredState);
    }
  }, [state]);


  useEffect(() => {
    if (props.initializeFromStorage && !isOnline) {
      storage?.get('state').then((stateFromStorage) => {
        if (stateFromStorage !== null) {
          console.log('OFFLINE: Grabbing from storage ');
          dispatch({ type: CrewHubActionTypes.setState, payload: {...stateFromStorage, isOnline: false}});
        }
      });
    }
  }, [storage, isOnline]);




  return (
    <CrewHubStoreContext.Provider value={state}>
      <CrewHubStoreDispatchContext.Provider value={dispatch}>
        {props.children}
      </CrewHubStoreDispatchContext.Provider>
    </CrewHubStoreContext.Provider>
  );
}



export const CrewHubStoreContext = createContext<CrewHubState>(emptyCrewHubState);
export const CrewHubStoreDispatchContext = createContext<Dispatch<CrewHubAction>>(
  (value: CrewHubAction) => undefined
);

export function useCrewHubStore() {
  return useContext(CrewHubStoreContext);
}

export function useCrewHubStoreDispatch() {
  return useContext(CrewHubStoreDispatchContext);
}
