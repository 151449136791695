import { IonContent, IonPage } from '@ionic/react';
import { SsrDetail, PageHeader } from '@components';
import { useCrewHubStore } from '@hooks';
import { RouteComponentProps } from 'react-router-dom';
import { Flight } from '@models';
import { findSelectedFlightFromState } from '@utilities';

export interface SsrDetailsPageProps
  extends RouteComponentProps<{
    flightId: string;
  }> {}

export function SsrDetailsPage( {match}: SsrDetailsPageProps ) {
  const state = useCrewHubStore();
  const flight: Flight | undefined =
    state.flights && findSelectedFlightFromState(state.flights, match.params.flightId);

  return (
    <IonPage>
      <PageHeader
        showFlightNumber={true}
        flightNumber={flight?.flightNumber}
        showLogo={false}
      />
      <IonContent fullscreen>
        {flight && <SsrDetail flight={flight} />}
      </IonContent>
    </IonPage>
  );
}
