import {addDays, addHours, addMinutes, compareAsc, format, parseISO, sub} from 'date-fns';
import { TimePeriod } from '@models';

export function getTimePeriod(currentDate: Date): TimePeriod {
  const timezoneOffset = currentDate.getTimezoneOffset();
  const offsetHours = Math.abs(Math.trunc(timezoneOffset / 60));
  const offsetMinutes = Math.abs(timezoneOffset % 60);
  const utcPastMidnight =
    currentDate.getHours() * 60 + currentDate.getMinutes() + timezoneOffset >=
    1440; //1440 = 24 hours in minutes
  const utcBeforeMidnight =
    currentDate.getHours() * 60 + currentDate.getMinutes() + timezoneOffset < 0;

  let adjustedStartDate: Date;
  let adjustedEndDate: Date;

  if (timezoneOffset > 0 && utcPastMidnight) {
    adjustedStartDate = addDays(currentDate, -3);
    adjustedEndDate = addDays(currentDate, 2);
    adjustedStartDate.setUTCHours(offsetHours, offsetMinutes, 0, 0);
    adjustedEndDate.setUTCHours(offsetHours - 1, 59, 59, 999);
  } else if (timezoneOffset > 0) {
    adjustedStartDate = addDays(currentDate, -3);
    adjustedEndDate = addDays(currentDate, 3);
    adjustedStartDate.setUTCHours(offsetHours, offsetMinutes, 0, 0);
    adjustedEndDate.setUTCHours(offsetHours - 1, 59, 59, 999);
  } else if (timezoneOffset === 0) {
    adjustedStartDate = addDays(currentDate, -3);
    adjustedEndDate = addDays(currentDate, 2);

    adjustedStartDate.setUTCHours(0, 0, 0, 0);
    adjustedEndDate.setUTCHours(23, 59, 59, 999);
  } else if (utcBeforeMidnight) {
    adjustedStartDate = addDays(currentDate, -3);
    adjustedEndDate = addDays(currentDate, 3);
    adjustedStartDate.setUTCHours(
      24 - offsetHours,
      offsetMinutes > 0 ? 60 - offsetMinutes : 0,
      0,
      0
    );
    adjustedEndDate.setUTCHours(23 - offsetHours, 59, 59, 999);
  } else {
    adjustedStartDate = addDays(currentDate, -3);
    adjustedEndDate = addDays(currentDate, 2);
    adjustedStartDate.setUTCHours(
      24 - offsetHours,
      offsetMinutes > 0 ? 60 - offsetMinutes : 0,
      0,
      0
    );
    adjustedEndDate.setUTCHours(23 - offsetHours, 59, 59, 999);
  }

  const to = adjustedEndDate.toISOString();
  const from = adjustedStartDate.toISOString();

  return { to, from };
}

export function getTodayUTC(): TimePeriod {
  const now = new Date();
  const beginningOfDayUTC = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0)
  );
  const endOfDayUTC = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      23,
      59,
      59
    )
  );
  const from = beginningOfDayUTC.toISOString().replace('Z', ''); //remove the Z at the end
  const to = endOfDayUTC.toISOString().replace('Z', '');

  return { from, to };
}

export function isWithinDaysOfLastUpdate(
  days: number,
  lastUpdate: string
): boolean {
  return compareAsc(sub(new Date(), { days: 1 }), new Date(lastUpdate)) === -1;
}

export function isValidDate(date: Date): boolean {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date.valueOf())
  );
}

export const formatTimeToLocalAirport = (time: string | undefined, offset: string | undefined ) => {
  if (!time){
    return '--:--'
  }
  const offsetString = offset || '00:00';
  // Parse the input date-time string
  const inputDateTime = parseISO(time);
  const browserTimeZone = inputDateTime.getTimezoneOffset();
  const browserOffsetHours = browserTimeZone > 0 ? Math.abs(Math.trunc(browserTimeZone / 60)): -Math.abs(Math.trunc(browserTimeZone / 60));
  const browserOffsetMinutes = browserTimeZone > 0 ? Math.abs(browserTimeZone % 60) : -Math.abs(browserTimeZone % 60);
  const offsetHours = parseInt(offsetString.split(':')[0]);
  const offsetMinutes = parseInt(offsetString.split(':')[1]);
  const totalOffsetHours = browserOffsetHours + offsetHours;
  const totalOffsetMinutes = browserOffsetMinutes + offsetMinutes;
  const adjustedTime = addHours(inputDateTime, totalOffsetHours);
  const adjustedTimeWithMin = addMinutes(adjustedTime, totalOffsetMinutes);
  return format(adjustedTimeWithMin, "HH:mm");
};

export const convertTimeToLocal = (time: string | undefined, offset: number | undefined) => {
  if (!time || !offset) {
    return '';
  }
  // Create a Date object from the input time string
  const inputDate = new Date(time);

  // Calculate the local time by adding the offset in minutes
  const localDate = new Date(inputDate.getTime() + offset * 60000);

  // Convert the local date to an ISO string in the desired format
  const localISOString = localDate.toISOString().slice(0, -5); // Remove trailing 'Z'

  return localISOString;
}
