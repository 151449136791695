import { gql } from '@apollo/client';

export const seatMapQuery = gql`
  query seatMap(
    $flightNum: String!
    $flightDate: ISO8601DateTime!
    $origin: String!
    $destination: String!
  ) {
    seatMap(
      flightNum: $flightNum
      flightDate: $flightDate
      origin: $origin
      destination: $destination
    ) {
      errorCode
      errorMessage
      seatMap
    }
  }
`;
