import { UnitInformation } from '../nav-models';
import { UnitType } from '../constants';
import { MockPassengerBTWithSsrs } from './mock-passenger-data';

export const NiceSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Reserved',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const NiceSeatCheckedIn = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'CheckedIn',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const NicerSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUYhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Reserved',
  designator: '1F',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'PREMIUM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const NicerSeatCheckedIn = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUYhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Reserved',
  designator: '1F',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'PREMIUM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const NicestSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkEhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Reserved',
  designator: '2A',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'BUSINESS',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const NicestSeatCheckedIn = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkEhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'CheckedIn',
  designator: '2A',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'BUSINESS',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const UnknownSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Unknown',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const BrokenSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Broken',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const EmptySeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkMhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Open',
  designator: '2C',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'PREMIUM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: null,
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const BreezeThruSeatNoChange: UnitInformation = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkMhWQ--',
  availability: 'Reserved',
  designator: '4F',
  text: '',
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  passenger: {
    recordLocator: '',
    passengerId: 1234,
    name: {
      first: 'ron',
      last: 'weasley'
    },

    passengerInformation: {
      dateOfBirth: '04/44'
    },
    services: [
      {
        segments: [
          {
            segmentKey: '234',
            isBreezeThru: true,
            seatChangeRequired: false,
            legs: [
              {
                ssrs: [],
                unitDesignator: null
              }
            ]
          }
        ]
      }
    ]
  }
};

export const BreezeThruSeatChange: UnitInformation = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMkMhWQ--',
  availability: 'Reserved',
  designator: '4F',
  text: '',
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  passenger: {
    recordLocator: '',
    passengerId: 1234,
    name: {
      first: 'ron',
      last: 'weasley'
    },

    passengerInformation: {
      dateOfBirth: '04/44'
    },
    services: [
      {
        segments: [
          {
            segmentKey: '234',
            isBreezeThru: true,
            seatChangeRequired: true,
            legs: [
              {
                ssrs: [],
                unitDesignator: null
              }
            ]
          }
        ]
      }
    ]
  }
};

export const LabelRulerWithNum = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Unknown',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: '1',
  type: UnitType.LabelRuler,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const LabelRulerWithLetter = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Unknown',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: '1D',
  type: UnitType.LabelRuler,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const ExitSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Unknown',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: '1',
  type: UnitType.Exit,
  x: 7,
  y: 2,
  zone: 1
} as UnitInformation;

export const WheelchairSeat = {
  unitKey: 'TVghMjUzISAhNjM3OTY1MDk5MDAwMDAwMDAwIVRQQSFDSFMhMUQhWQ--',
  allowedSsrs: [],
  assignable: true,
  availability: 'Reserved',
  designator: '1D',
  properties: [
    {
      code: 'AISLE',
      value: 'True'
    },
    {
      code: 'LEGROOM',
      value: 'True'
    },
    {
      code: 'TCC',
      value: 'Y'
    },
    {
      code: 'BRDZONE',
      value: '4'
    }
  ],
  group: 1,
  text: '1',
  type: UnitType.NormalSeat,
  x: 7,
  y: 2,
  zone: 1,
  passenger: MockPassengerBTWithSsrs
} as UnitInformation;
