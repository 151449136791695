import { gql } from '@apollo/client';

export const flightUpdatesSubscription = gql`
  subscription FlightUpdates($flightName: String!) {
    flightUpdates(flightName: $flightName) {
      actualArrivalAirport
      actualDepartureAirport
      aircraftType
      arrivalGate
      departureGate
      crew {
        firstname
        lastname
        number
        rank
        rosterDesignator
      }
      estimatedTimes {
        estimatedBlockOff
        estimatedBlockOn
      }
      flightDate
      flightNumber
      flightName
      offsetDa
      offsetAa
      scheduledArrivalAirport
      scheduledDepartureAirport
      sta
      staLocal
      status
      std
      stdLocal
      tailNumber
      uniqueId
    }
  }
`;
