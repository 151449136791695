import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote, useIonRouter
} from '@ionic/react';

import {RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import { logOutOutline, logOutSharp } from 'ionicons/icons';
import './menu.css';
import { CREWHUB_ROUTES, NavigationLink } from '@models';
import {useSession, useStorage} from '@hooks';

export interface MenuProps extends RouteComponentProps {
  links: NavigationLink[];
  redirectToOnSignout: string;
}


function Menu(props: MenuProps) {
  const router = useIonRouter();
  const { logout } = useSession();

  const handleSignOutRedirect = () => {
    router.push(props.redirectToOnSignout);
  };

  const handleLogout = async () => {
    handleSignOutRedirect();
    try {
      await logout();
    } catch {
      console.log('user not signed in');
    }
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList>
          <IonNote>Menu</IonNote>
          {props.links.map((link, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={router.routeInfo.pathname === link.url ? 'selected' : ''}
                  routerLink={link.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={link.iosIcon}
                    md={link.mdIcon}
                    color="light"
                  />
                  <IonLabel color="light">{link.title}</IonLabel>
                </IonItem>
                <IonItem
                  onClick={() => handleLogout()}
                  routerLink={CREWHUB_ROUTES.LOGIN}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={logOutOutline}
                    md={logOutSharp}
                    color="light"
                  />
                  <IonLabel color="light">Sign Out</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
}

export default withRouter(Menu);
