import styled from '@emotion/styled';
import { FlightStatus } from '@models';
import { convertFlightStatus, colorChanger } from '@utilities';

export interface BadgeProps {
  flightStatus: FlightStatus;
  primary: boolean;
}

export function StatusBadge({ ...props }: BadgeProps) {
  const StyledStatusBadge = styled.div(
    ({ theme }) => `
    background-color: ${colorChanger(props.flightStatus)};
    width: ${props.primary ? '85px' : 'auto'};
    height: '75%';
    margin: ${props.primary ? '0 auto 15px' : '2px auto'};
    border-radius: 2px;
    border: none;
    font-size: ${props.primary ? theme.fontSizes.small : '0.5rem'};
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props.primary ? '2px' : '2px 5px'};
    text-align: center;
    font-weight: bold;
    @media (min-width: ${theme.breakpoints.medium}) {
      font-size: ${props.primary ? '1.5rem' : theme.fontSizes.small};
      width: ${props.primary ? '75%' : '100px'}
    }
  `
  );

  return (
    <StyledStatusBadge>
      {convertFlightStatus(props.flightStatus)}
    </StyledStatusBadge>
  );
}

export default StatusBadge;
