import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Flight, SSR, UnitInformation, UnitType } from '@models';

import { compareFlightToUrl, findLeg, findSegment } from '@utilities';
import { useCrewHubStore } from '@hooks';
import { useState } from 'react';
import { IonModal } from '@ionic/react';
import GuestInfoModal from '../guest-info-modal/guest-info-modal';
import { useParams } from 'react-router-dom';
import SsrIcon from '../ssr-icon/ssr-icon';

export interface SeatProps {
  // TODO: Update to be array of some extension type that includes manifest information (future ticket)
  seat: UnitInformation;
}

const Box = styled.div`
  height: 55px;
  width: 50px;
`;

const BreezeThruMark = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const Center = styled.div`
  padding-top: 6px;
  padding-left: 5px;
`;

function getBoxStyles(seat: UnitInformation, ssrs: SSR[]) {
  if (seat.type === UnitType.LabelRuler) {
    // Filters out Column Letters and Not Numbers so row numbers show up and column letters aren't duplicated
    return seat.text && /[a-z]/i.test(seat.text)
      ? {
          display: 'none'
        }
      : {
          display: 'flex',
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none'
        };
  }

  if (seat.type === UnitType.Exit) {
    return {
      display: 'none'
    };
  }

  return {};
}

function breezeThruStyles(
  seat: UnitInformation,
  breezeThru: boolean,
  seatChangeRequired: boolean
) {
  const seatReserved =
    seat.availability === 'Reserved' || seat.availability === 'CheckedIn';

  if (seatReserved && breezeThru && !seatChangeRequired) {
    return {
      borderTop: '20px solid transparent',
      borderLeft: '20px solid white'
    };
  }
  if (seatReserved && breezeThru && seatChangeRequired) {
    return {
      borderTop: '20px solid transparent',
      borderLeft: '20px solid #FF527B'
    };
  }

  return { display: 'none' };
}

export function Seat({ seat }: SeatProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const state = useCrewHubStore();

  const { flightId } = useParams<{ flightId?: string }>();

  const flight =
    state.flights &&
    state.flights.find((flight: Flight) => {
      return compareFlightToUrl(flight, flightId);
    });

  const seatReserved =
    seat.availability === 'Reserved' || seat.availability === 'CheckedIn';
  const routeChange = () => {
    if (!seatReserved || !seat.passenger) {
      return;
    }
    setShowModal(true);
  };

  // handle connections
  const segment = findSegment(flight, seat.passenger);
  const leg = findLeg(flight, seat.passenger);
  const ssrs: SSR[] = leg?.ssrs || [];
  const breezeThru = !!segment?.isBreezeThru;
  const seatChangeRequired = !!segment?.seatChangeRequired;
  const isBreezeThru = seatReserved && breezeThru;
  const hasSsrs = ssrs.length > 0;

  const theme = useTheme();

  const setSeatColor = (seat: UnitInformation) => {
    if (
      seat.properties?.find((prop) => prop.code === 'BUSINESS') &&
      seatReserved
    ) {
      return theme.colors.nicest;
    } else if (
      seat.properties?.find((prop) => prop.code === 'PREMIUM') &&
      seatReserved
    ) {
      return theme.colors.nicer;
    } else if (seatReserved) {
      return theme.colors.nice;
    }
    return 'rgba(128, 128, 128, 0.5)';
  };

  return (
    <>
      <Box
        style={{
          gridColumn: `${seat.x}/ span 2`,
          gridRow: `${seat.y}/span 1`,
          msGridColumns: `${seat.x}/ span 2`,
          msGridRows: `${seat.y}/span 1`,
          position: 'relative',
          backgroundColor: setSeatColor(seat),
          border: '1px solid rgba(128, 128, 128, 0.5)',
          ...getBoxStyles(seat, ssrs)
        }}
        onClick={routeChange}
        data-testid={seatReserved ? 'seat-occupied' : 'seat-unoccupied'}
      >
        {hasSsrs && (
          <Center>
            <SsrIcon ssrs={ssrs} size={40} />
          </Center>
        )}
        {isBreezeThru && (
          <BreezeThruMark
            style={{
              ...breezeThruStyles(seat, breezeThru, seatChangeRequired)
            }}
          />
        )}
        {seat.text}
      </Box>
      {/* full-page ion modal is here because the onclick is in the <Box /> */}
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <GuestInfoModal
          setShowModal={setShowModal}
          passenger={seat.passenger}
        />
      </IonModal>
    </>
  );
}

export default Seat;
