import { clientFactory, convertTimeToLocal, extractFlightNum } from '@utilities';
import {
  DATETIME_FORMAT,
  GraphResponse,
  ScheduleFlight,
  SeatMap, SeatMapResponse,
  SeatMapsResponse
} from '@models';
import { seatMapQuery } from '@graphql';
import {format} from "date-fns";
const timeStamp = () => format(new Date(), DATETIME_FORMAT);

export const seatMapNavitaireApi = async (
  flightSchedule: ScheduleFlight[],
  baseUrl: string,
  token?: string,
) => {
  const client = clientFactory(
    token ?? '',
    `${baseUrl}/graph/authenticated/seatMap` || ''
  );

  const queries = flightSchedule.map((flight: ScheduleFlight) => {
    return client.query({
      query: seatMapQuery,
      variables: {
        flightNum: extractFlightNum(flight.activityCode),
        flightDate: convertTimeToLocal(flight.start, flight.startLocalTimeDiff),
        origin: flight.startAirportCode,
        destination: flight.endAirportCode
      }
    });
  });

  const responses: GraphResponse<SeatMapResponse>[] = await Promise.all(
    queries
  );

  const seatMaps: SeatMap[] = responses.map(
    (response: GraphResponse<SeatMapResponse>) => {
      const seatMap = response.data.seatMap;
      return seatMap.seatMap as SeatMap;
    }
  );

  const seatMapsResponse: SeatMapsResponse = {
    seatMaps: seatMaps,
    responseCode: '200',
    errorMessage: '',
    time: timeStamp(),
  }
  return seatMapsResponse;
};
