import {
  ScheduleFlight,
  SeatMap,
  SeatMapSubscription,
  SeatMapUpdateResponse
} from '@models';
import * as appsyncMethods from '@utilities/src/lib/app-sync-utils/app-sync-utils';
import { createSeatMapName } from '@utilities';
import { useEffect, useState } from 'react';
import { seatMapUpdatesSubscription } from '@graphql/lib/seat-map-updates-subscription';
import { useSession } from '../msal/msal';
import {useCrewHubStore, useCrewHubStoreDispatch} from "../crew-hub-store/crew-hub-store";
import {CrewHubActionTypes} from "../crew-hub-store/crew-hub-store-actions";
import { GraphQLSubscription } from '@aws-amplify/api';
import { environment } from 'environments/environment';

export function useSeatMapSubscription() {
  const dispatch = useCrewHubStoreDispatch();
  const [flightSchedule, setFlightSchedule] = useState<ScheduleFlight[]>([]);
  const [token, setToken] = useState<string>('');
  const store = useCrewHubStore();
  const { acquireTokenSilent } = useSession();
  const online = store.isOnline;

  acquireTokenSilent([environment.apiScope]).then((accessToken) => {
    setToken(accessToken);
  })

  useEffect(() => {
    if (store && store.schedule && store.schedule.length !== 0 && online) {
      setFlightSchedule(store.schedule);
    }
  }, [store]);

  useEffect(() => {
    if (flightSchedule.length !== 0 && token && online) {
      // clear all existing subscriptions
      if(store?.seatMapSubscriptions) {
        store.seatMapSubscriptions.forEach((sub) => {
          try {
            sub.subscription.unsubscribe();
          } catch {
            console.log("Failed to unsubscribe");
          }
        });
      }

      dispatch({
        type: CrewHubActionTypes.clearSeatMapSubscriptions,
        payload: null
      })

      flightSchedule.forEach((flight: ScheduleFlight) => {
        const seatMapName = createSeatMapName(flight)

        const subscription: GraphQLSubscription<any> = appsyncMethods.subscribe(
          seatMapUpdatesSubscription,
          { seatMapName: seatMapName },
          (response: SeatMapUpdateResponse) => {
            const updatedSeatMap: SeatMap = response.seatMapUpdates.seatMap as SeatMap;

            dispatch({
              type: CrewHubActionTypes.updateSeatMap,
              payload: updatedSeatMap
            });
          },
          token
        );

        const payload: SeatMapSubscription = {
          seatMapName: seatMapName,
          subscription: subscription as GraphQLSubscription<any>
        }

        // store subscription
        dispatch({
          type: CrewHubActionTypes.setSeatMapSubscriptions,
          payload: payload
        });
      });
    }
  }, [flightSchedule, token, online]);
}
