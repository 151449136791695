import { Flight } from '@models';
import ScheduleListItem from '../schedule-list-item/schedule-list-item';
import styled from '@emotion/styled';
import { add, format, getDate, compareAsc, endOfDay } from 'date-fns';
import React from 'react';
import { ApolloError } from '@apollo/client/errors';

export interface ScheduleListViewProps {
  flights: Flight[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const ScheduleListViewStyles = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.primary};
  `
);

const DateGroup = styled.div(
  ({ theme }) => `
    text-align: right;
    padding-top: 1rem;
    padding-right: 1rem;
    font-family: ${theme.fonts.secondary};
    font-size: 1rem;
    font-weight: 400;
    color: ${theme.colors.accentGrey};
    border-bottom: ${theme.borders.smallGrey};
  `
);

const NoFlights = styled.h2(
  ({ theme }) => `
    text-align: right;
    font-size: ${theme.fontSizes.medium};
    font-family: ${theme.fonts.secondary};
    color: ${theme.colors.white};
    padding: 10px 16px 0 0;
    margin-top: 0;
  `
);

const randomMessage = (): string => {
  const rand = Math.floor(Math.random() * 3);
  switch (rand) {
    case 0:
      return 'No flights. Enjoy your day off!';
    case 1:
      return 'No flights. No work. Ohhhh yeah!';
    case 2:
      return 'No flights. Keep it breezy on your day off!';
    default:
      return 'No Flights Scheduled';
  }
};

export function ScheduleListView({
  flights,
  loading,
  error,
}: ScheduleListViewProps) {

  // Initial sort by departure time so generateListItem() works properly with updates
  if (flights) {
    flights.sort((a: Flight, b: Flight) => {
      if (a.std && b.std && a.std > b.std) {
        return 1;
      } else {
        return -1;
      }
    });
  }
  const noFlights = (key: string, flightDate: Date) => (
    <React.Fragment key={`${key}-no-flight-header`}>
      <DateGroup>{format(flightDate, 'EEEE, P')}</DateGroup>
      {loading ? (
        <NoFlights>Loading flights...</NoFlights>
      ) : error ? (
        <NoFlights>Something went wrong retrieving your flights.</NoFlights>
      ) : (
        <NoFlights>{randomMessage()}</NoFlights>
      )}
    </React.Fragment>
  );

  if (!Array.isArray(flights) || !(flights?.length > 0)) {
    return (
      <>
        {noFlights('1', new Date())}
        {noFlights('2', add(new Date(), { days: 1 }))}
      </>
    );
  }

  function generateListItem(flight: Flight) {
    return <ScheduleListItem flight={flight}></ScheduleListItem>;
  }

  // make sure the first flight label is shown always
  let flightDayOfMonth = getDate(new Date());
  
  // using end of today to handle end of the month/year bug
  const endOfToday = endOfDay(new Date());
  
  const firstFlightIsNotToday =
    compareAsc(new Date(flights[0].stdLocal || ''), endOfToday) === 1;
  
  const lastFlightIsToday =
    compareAsc(
      new Date(flights[flights.length - 1].stdLocal || ''),
      endOfToday
    ) === -1;

  const listItems: any = [];
  flights.forEach((flight, index) => {
    if (!flight || !flight.uniqueId) {
      return;
    }

    const flightDate = new Date(flight?.stdLocal || '');
    const currentFlightDayOfMonth = getDate(flightDate);

    if (index === 0 && firstFlightIsNotToday) {
      listItems.push(noFlights(flight.uniqueId, new Date()));
    }

    if (
      (index === 0 && !firstFlightIsNotToday) ||
      flightDayOfMonth !== currentFlightDayOfMonth
    ) {
      listItems.push(
        <React.Fragment key={flight.uniqueId}>
          <DateGroup>{format(flightDate, 'EEEE, P')}</DateGroup>
          {generateListItem(flight)}
        </React.Fragment>
      );
      flightDayOfMonth = currentFlightDayOfMonth;
    } else {
      listItems.push(
        <React.Fragment key={flight.uniqueId}>
          {generateListItem(flight)}
        </React.Fragment>
      );
    }
  });

  if (lastFlightIsToday) {    
    listItems.push(noFlights('last', add(new Date(flights[flights.length - 1].stdLocal || ''), { days: 1 })));
  }

  return (
      <ScheduleListViewStyles>{listItems}</ScheduleListViewStyles>
      );
}

export default ScheduleListView;
