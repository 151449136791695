import { Crew } from '../nav-models';
import { CrewRank } from '../constants';

export const MockCaptain: Crew = {
  firstname: 'Scooby',
  lastname: 'Doo',
  number: '487310',
  rank: 'CA' as CrewRank
};

export const MockFirstOfficer: Crew = {
  firstname: 'Shaggy',
  lastname: 'Rogers',
  number: '487315',
  rank: 'FO' as CrewRank
};

export const MockFlightAttendantOne: Crew = {
  firstname: 'Fred',
  lastname: 'Jones',
  number: '487305',
  rank: 'FA' as CrewRank
};

export const MockFlightAttendantTwo: Crew = {
  firstname: 'Velma',
  lastname: 'Dinkley',
  number: '487305',
  rank: 'FA' as CrewRank
};

export const MockFlightAttendantThree: Crew = {
  firstname: 'Daphne',
  lastname: 'Blake',
  number: '487305',
  rank: 'FA' as CrewRank
};

export const MockCrew: Crew[] = [
  MockCaptain,
  MockFirstOfficer,
  MockFlightAttendantOne,
  MockFlightAttendantTwo,
  MockFlightAttendantThree
];

export const MockCrew_NoFirstNames = MockCrew.map((crew) => ({
  ...crew,
  firstname: ''
}));

export const MockCrew_NoLastNames = MockCrew.map((crew) => ({
  ...crew,
  lastName: ''
}));

export const MockCrew_NoRank = MockCrew.map((crew) => ({
  ...crew,
  rank: '' as CrewRank
}));
