import { IonContent, IonPage } from '@ionic/react';
import { PageHeader, GuestListView } from '@components';
import { useCrewHubStore } from '@hooks';
import { RouteComponentProps } from 'react-router-dom';
import { Flight } from '@models';
import { findSelectedFlightFromState } from '@utilities';

export interface GuestListPageProps
  extends RouteComponentProps<{
    flightId: string;
  }> {}

export function GuestListPage( {match}: GuestListPageProps ) {
  const state = useCrewHubStore();
  const flight: Flight | undefined =
    state.flights && findSelectedFlightFromState(state.flights, match.params.flightId);

  return (
    <IonPage>
      <PageHeader showFlightNumber={true} flightNumber={flight?.flightNumber} />
      <IonContent fullscreen>
        <GuestListView flight={flight} />
      </IonContent>
    </IonPage>
  );
}
