import {gql} from "@apollo/client";

export const crewScheduleQuery = gql`
  query crewFlights($from:ISO8601DateTime!, $to:ISO8601DateTime!) {
    crewFlights(from: $from, to: $to) {
      rosterActivities {
        uniqueId
        activityType
        activitySubType
        activityCode
        startAirportCode
        endAirportCode
        start
        end
        startLocalTimeDiff
        endLocalTimeDiff
        startBaseTimeDiff
        endBaseTimeDiff
        equipmentType
        equipmentVersion
        complement
        rosterDesignator
        legalException
        credit
        comment
        assignedRank
        notes
        pairingId
        pairingStart
        pairingEnd
        pairingName
        code
        refUniqueId
        confirmed
        isCarryOverActivity
      }
      errorCode
      errorMessage
    }
  }
`
