import styled from '@emotion/styled';
import Seat from '../seat/seat';
import {
  Flight,
  Manifest,
  SeatMap,
  SeatMapColumnLabelRow,
  UnitInformation
} from '@models';
import React, { useState } from 'react';
import {
  addMissingFirstRowLabels,
  addPaxToSeats,
  findManifestForFlight,
  findSeatMapForFlight,
  removeExtraSectionLabels,
  setUpSeatMap
} from '@utilities';
import { IonButton, IonModal } from '@ionic/react';
import { LegendModal } from '../legend-modal/legend-modal';
import { useCrewHubStore } from '@hooks';

export interface SeatMapStructureProps {
  flight: Flight;
}

const StyledSeatMapStructure = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 30px;
`;

const SeatGrid = styled.div`
  display: grid;
  row-gap: 2px;
  column-gap: 4px;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  font-size: 1.25rem;
`;

const ExitRow = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.lightRed};
  height: 55px;
  width: 7px;
  position: absolute;
`
);

const LeftExitRow = styled(ExitRow)`
  left: -16px;
`;

const RightExitRow = styled(ExitRow)`
  right: -15px;
`;

const ColLabel = styled.div(
  ({ theme }) => `
  text-align: center;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.large};
  position: relative;
  z-index: 10;
`
);

const LegendGrid = styled.div`
  display: grid;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  font-size: 1.25rem;
  padding-left: 228px;
`;

const filterColumnLabels = (columnLabelSections: SeatMapColumnLabelRow[]): SeatMapColumnLabelRow[] => {
  return columnLabelSections.map(label => {
    // Use a Set to track unique labels
    const uniqueLabels = new Set();

    // Filter unique labels
    label.labels = label.labels.filter(l => {
      const friendKey = `${l.column}-${l.letter}`;

      if (!uniqueLabels.has(friendKey)) {
        uniqueLabels.add(friendKey);
        return true;
      }

      return false;
    });

    return label;
  });
}

export function SeatMapStructure({ flight }: SeatMapStructureProps) {
  const state = useCrewHubStore();
  const seatMap: SeatMap | undefined =
    state.seatMaps &&
    flight &&
    findSeatMapForFlight(state.seatMaps, flight);

  const manifest: Manifest | undefined =
    state.manifests &&
    flight &&
    findManifestForFlight(state.manifests, flight);

  const seatsWithPax =
    seatMap &&
    flight &&
    addPaxToSeats(
      seatMap?.seats,
      manifest?.passengerList,
      flight
    );
  
  const seats = seatsWithPax ? seatsWithPax : [];
  const setUp = setUpSeatMap(seats);
  const [showModal, setShowModal] = useState(false);
  // through trial and error, the highest x value from the seat map - 1 looks the best in the browser
  const columns = setUp.columns - 1;
  const allExitRows = setUp.allExitRows;
  const middleExitRows = setUp.middleExitRows;
  const columnLabelSectionsInitial = setUp.columnLabelSections;
  const flightType = flight?.aircraftType === ("E95" || "E90") ? "E95" : "A220";
  const columnLabelSectionsRowAdded = addMissingFirstRowLabels(columnLabelSectionsInitial);
  const columnLabelSections = removeExtraSectionLabels(columnLabelSectionsRowAdded, middleExitRows[0], flightType);
  const uniqueColumnLabelSections = filterColumnLabels(columnLabelSections);

  return (
    <StyledSeatMapStructure>
      <LegendGrid>
        <IonButton
          onClick={() => setShowModal(true)}
          data-testid="legend-button"
        >
          Legend
        </IonButton>
      </LegendGrid>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <LegendModal setShowModal={setShowModal} data-testid="show-modal" />
      </IonModal>
      <SeatGrid style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
        {seats.map((seat: UnitInformation) => (
          <Seat seat={seat} key={seat.designator}></Seat>
        ))}

        {middleExitRows.length > 0 &&
          middleExitRows.map((exitRow: number, index: number) => (
            <React.Fragment key={`fragment-${index}`}>
              <LeftExitRow
                style={{
                  gridRow: `${exitRow}/span 1`,
                  msGridRows: `${exitRow}/span 1`
                }}
                key={`left-exit-${index}`}
              />
              <RightExitRow
                style={{
                  gridRow: `${exitRow}/span 1`,
                  msGridRows: `${exitRow}/span 1`
                }}
                key={`right-exit-${index}`}
              />
            </React.Fragment>
          ))}

        {uniqueColumnLabelSections.length > 0 &&
          uniqueColumnLabelSections.map((label: SeatMapColumnLabelRow) => {
            return label.labels.map(
              (letter: { column: number; letter: string }) => (
                <ColLabel
                  style={{
                    gridColumn: `${letter.column}/ span 2`,
                    msGridColumns: `${letter.column}/ span 2`,
                    gridRow: `${label.row}/span 1`,
                    msGridRows: `${label.row}/span 1`
                  }}
                  key={`${label.row}-${letter.letter}`}
                >
                  {letter.letter}
                </ColLabel>
              )
            );
          })}
      </SeatGrid>
    </StyledSeatMapStructure>
  );
}

export default SeatMapStructure;
