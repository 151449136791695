import blueBreezeCheckmark from '../../../../assets/src/lib/blue-breeze-checkmark.png';

export interface CrewHubLogoProps {
  pxHeight?: string;
  pxWidth?: string;
}


export function CrewHubLogo({pxHeight = '24px', pxWidth = '201px'}: CrewHubLogoProps) {
  return (
      <img src={blueBreezeCheckmark} alt="logo" style={{height: pxHeight, width: pxWidth}}/>
  );
}

export default CrewHubLogo;
