import {
  useCrewHubStore,
  useCrewHubStoreDispatch
} from '../crew-hub-store/crew-hub-store';
import {ApolloError, useApolloClient} from '@apollo/client';
import { flightQuery } from '@graphql/lib/flight';
import { useEffect, useState } from 'react';
import { DATETIME_FORMAT, Flight, FlightsResponse } from '@models';
import { CrewHubActionTypes } from '../crew-hub-store/crew-hub-store-actions';
import { format } from 'date-fns';
import { addReportTime } from '@utilities';

const timeStamp = () => format(new Date(), DATETIME_FORMAT);

export function useFlightsForCrew():{
  loading: boolean;
  error?: ApolloError;
} {
  const store = useCrewHubStore();
  const dispatch = useCrewHubStoreDispatch();
  const [flightIds, setFlightIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>();
  const schedule = store.schedule;
  const client = useApolloClient();
  const online = store.isOnline;

  useEffect(() => {
    if(schedule && schedule.length > 0 && online) {
      const ids = schedule.map((item) => item.refUniqueId && item.refUniqueId.toString()) as string[];
      setFlightIds(ids);
    }
  }, [schedule, online])

  useEffect(() => {
    if(flightIds.length > 0 && online) {
      const fetchData = async () => {
      setLoading(true);
        try {
          const queries = flightIds.map((id) => (
            client.query({
              query: flightQuery,
              variables: { uniqueId: id },
              fetchPolicy: 'network-only',
            })
          ));
          const dataArray = await Promise.all(queries);
          const mergedFlights = dataArray.flatMap((data: any) => data.data.flight);
          const newFlights = mergedFlights.map((flightResponse) => ({
            ...flightResponse.flight,
            reportTime: addReportTime(flightResponse.flight),
          }));
          setFlightsInStore(newFlights);
          setError(undefined);
          setLoading(false);
          if(newFlights.length === 0){
            dispatch({
              type: CrewHubActionTypes.setLoading,
              payload: false
            })
          }
        } catch (e: any) {
          console.error(e);
          setError(e as ApolloError)
          setFlightsInStore(store?.flights || [])
          setLoading(false);
          dispatch({
            type: CrewHubActionTypes.setLoading,
            payload: false
          })
        }
      };

      fetchData().catch(console.error);
    }
  }, [flightIds, online])

  const setFlightsInStore = (flights: Flight[]) => {
    const responseToStore: FlightsResponse = {
      responseCode: error ? '!200' : '200',
      errorMessage: error ? error.message: '',
      time: timeStamp(),
      flights: flights
    };
    dispatch({
      type: CrewHubActionTypes.setFlightsResponse,
      payload: responseToStore,
    });
  };

 return { loading, error }
}

