import { ApolloError, useQuery } from '@apollo/client';
import {
  useCrewHubStore,
  useCrewHubStoreDispatch
} from '../crew-hub-store/crew-hub-store';
import { DATETIME_FORMAT, TimePeriod } from '@models';
import { getTimePeriod } from '@utilities';
import { crewScheduleQuery } from '@graphql';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import { CrewHubActionTypes } from '../crew-hub-store/crew-hub-store-actions';
import { useSession } from '../msal/msal';
import { ScheduleResponse } from '@models/src/lib/schedule-response';
import {emptyCrewHubState} from "@models/src/lib/mock-data/empty-crew-hub-state";

const timeStamp = () => format(new Date(), DATETIME_FORMAT);
export function useCrewSchedule( pollInterval = 1800000, scope: string): {
  loading: boolean,
  error: ApolloError | undefined;
  refetch: any;
} {
  const [token, setToken] = useState<string>('');
  const [skipCall, setSkipCall] = useState(true)
  const dispatch = useCrewHubStoreDispatch();
  const { to, from }: TimePeriod = getTimePeriod(new Date());
  const store = useCrewHubStore();
  const {acquireTokenSilent} = useSession();
  const online = store.isOnline;

  acquireTokenSilent([scope]).then((accessToken) => {
    setToken(accessToken);
  });

  useEffect(() =>{
    if(token && online){
      setSkipCall(false);
    } else if(!token || !online){
      setSkipCall(true);
    }
  }, [token, online])


  const  { data, error, loading, refetch}  = useQuery(crewScheduleQuery,
    {
      variables: {
        to,
        from
      },
      pollInterval: pollInterval,
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
      notifyOnNetworkStatusChange: true,
      skip: skipCall
    });

  useEffect(() => {
    if(loading && !store.loading){
      dispatch({
        type: CrewHubActionTypes.setLoading,
        payload: true
      })
    }

  }, [loading])


  const scheduleResponse = data?.crewFlights;

  useEffect(() => {
    const responseToStore = {
      responseCode: error ? '!200' : '200',
      errorMessage: error?.message || '',
      time: timeStamp(),
      schedule: store?.schedule || [],
    };

    if (error) {
      if(store.loading){
        dispatch({
          type: CrewHubActionTypes.setLoading,
          payload: false
        })
      }
      dispatch({
        type: CrewHubActionTypes.setScheduleResponse,
        payload: responseToStore as ScheduleResponse,
      });
    }

    // Successful response but empty flights then reset store
    if(scheduleResponse && scheduleResponse?.rosterActivities?.length === 0){
      const emptyState = cloneDeep(emptyCrewHubState);
      emptyState.scheduleResponse.responseCode = '200';
      dispatch({
        type: CrewHubActionTypes.setState,
        payload: emptyState
      })
      return;
    }

    // Successful response with flights
    if (scheduleResponse?.rosterActivities?.length > 0) {
      responseToStore.schedule = cloneDeep(scheduleResponse.rosterActivities);
      dispatch({
        type: CrewHubActionTypes.setScheduleResponse,
        payload: responseToStore as ScheduleResponse,
      });
    }
  }, [scheduleResponse, error]);


  return { loading: loading, error, refetch};
}

export default useCrewSchedule;
