import arrow from '../../../../assets/src/lib/arrow-thin-white.svg';
import plane from '../../../../assets/src/lib/plane-no-outline.svg';
import {IonButton, IonGrid, IonRippleEffect} from '@ionic/react';
import {Flight} from '@models/src/lib/nav-models';
import {FlightStatus} from "@models";
import styled from '@emotion/styled';
import StatusBadge from '../status-badge/status-badge';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {CREWHUB_ROUTES} from '@models';
import {createFlightId} from '@utilities';

export interface ScheduleListItemProps {
  flight: Flight;
}

const Col = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const InfoCol = styled(Col)`
  align-items: center;
  text-align: left;
`;

const Header = styled.h1(
  ({ theme }) => `
    font-size: 5rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
    @media(max-width: ${theme.breakpoints.medium}) {
      font-size: ${theme.fontSizes.xLarge};
      margin: 0;
    }
  `
);

const Icon = styled.img(
  ({ theme }) => `
    width: 50px;
    height: 50px;
    @media(max-width: ${theme.breakpoints.medium}) {
      width: 100%;
      height: 25px;
    }
  `
);

const ArrowIcon = styled(Icon)`
  margin-left: 0;
  margin-right: 2px;
`;

const PlaneIcon = styled(Icon)(
  ({ theme }) => `
    margin-bottom: 20px;
    width: 100%;
    @media(max-width: ${theme.breakpoints.medium}) {
      margin-bottom: 20px;
    }
  `
);

const Subheader = styled.p(
  ({ theme }) => `
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
    margin: 0;
    @media(max-width: ${theme.breakpoints.medium}) {
      font-size: ${theme.fontSizes.medium};
      margin: 0 0 5px 0;
    }
    & > span {
      font-weight: bold;
    }
  `
);

const TimeInfo = styled.p(
  ({ theme }) => `
    font-size: ${theme.fontSizes.large};
    margin: 5px 0 5px 0;
    @media(max-width: ${theme.breakpoints.medium}) {
      font-size: ${theme.fontSizes.medium};
      margin: 2px 0 2px 0;
    }
    @media(max-width: ${theme.breakpoints.small}) {
      font-size: ${theme.fontSizes.small};
    }
    & > span {
      font-weight: bold;
      margin-left: 25px;
      text-align: right;
      @media(max-width: ${theme.breakpoints.medium}) {
        margin-left: 10px;
      }
      @media(max-width: ${theme.breakpoints.medium}) {
        margin-left: 5px;
      }
    }
  `
);

const ReportInfo = styled(TimeInfo)(
  ({ theme }) => `
    margin-bottom: 0;
    margin-top: 30px;
    @media(max-width: ${theme.breakpoints.medium}) {
      margin-top: 15px
    }
    @media(max-width: ${theme.breakpoints.small}) {
      margin-top: 10px
    }
  `
);

const DepartInfo = styled(TimeInfo)(
  ({ theme }) => `
    margin-top: 0;
    margin-bottom: 5px;
    @media(max-width: ${theme.breakpoints.medium}) {
      margin-bottom: 2px;
    }
  `
);

const ScheduleListItemStyles = styled.div(
  ({ theme }) => `
    font-family: ${theme.fonts.primary};
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    display: grid;
    grid-template-columns: 1fr 0.25fr 1fr 1fr 0.25fr;
    border-bottom: ${theme.borders.smallGrey};
    margin: 0;
    padding: 0;
    align-items: center;
    width: 100%;
  `
);

const ListButton = styled(IonButton)`
  height: auto;
  --padding-start: 0;
  --padding-end: 0;
`;

export function ScheduleListItem({ flight }: ScheduleListItemProps) {
    const flightId = createFlightId(flight);
    const pathname = `${CREWHUB_ROUTES.FLIGHT_DETAILS}/${flightId}`;

  return (
    <ListButton
      routerLink={pathname}
      fill="clear"
      expand="full"
      className="schedule-list-item"
    >
      <ScheduleListItemStyles>
        <Col>
          <Header>{flight.actualDepartureAirport}</Header>
          <Subheader>
            Flight <span>{flight.flightNumber}</span>
          </Subheader>
        </Col>
        <div>
          <PlaneIcon src={plane} alt="plane-icon" className="plane-icon" />
        </div>
        <Col>
          <Header>{flight.actualArrivalAirport}</Header>
          <Subheader>
            Gate <span>{flight.departureGate || '--'}</span>
          </Subheader>
        </Col>
        <InfoCol>
          <IonGrid>
            <ReportInfo>
              {flight.reportTime && 'Report'}{' '}
              <span>
                {(flight.reportTime &&
                  format(new Date(flight.reportTime), 'H:mm')) ||
                  ''}{' '}
              </span>
            </ReportInfo>
            <DepartInfo>
              {flight.stdLocal && 'Depart'}{' '}
              <span>
                {flight.status === FlightStatus.Delayed || flight.status === FlightStatus.Canceled ?
                  flight.estimatedTimes?.estimatedBlockOff && flight.offsetDa && format(utcToZonedTime(new Date(flight.estimatedTimes.estimatedBlockOff), flight.offsetDa), 'H:mm')
                  : flight.stdLocal && format(new Date(flight.stdLocal), 'H:mm')}{' '}
              </span>
            </DepartInfo>
          </IonGrid>
          <StatusBadge flightStatus={flight.status} primary={true} />
        </InfoCol>
        <div>
          <ArrowIcon src={arrow} className="arrow-icon" />
        </div>
        <IonRippleEffect></IonRippleEffect>
      </ScheduleListItemStyles>
    </ListButton>
  );
}

export default ScheduleListItem;
