import { useTheme } from '@emotion/react';
import { IonContent, IonPage, IonRow, useIonRouter } from '@ionic/react';
import { Login, LoginProps } from '@components/lib/login/login';
import styled from '@emotion/styled';
import logo from '../../../../../libs/assets/src/lib/crew-hub-logo.svg';
import { useSession } from '@hooks';
import { Storage } from '@ionic/storage';

export interface LoginPageProps {
  authenticationCallback: () => void;
}

export function LoginPage(prop: LoginPageProps) {
  const theme: any = useTheme();
  const { fonts, colors, fontSizes } = theme;

  const Row = styled(IonRow)`
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0;
  `;

  const LoginPage = styled.div`
    background-color: ${colors.primary};
    height: 95%;
    align-items: center;
  `;

  const VersionNumber = styled.div`
    font-family: ${fonts.secondary};
    color: ${colors.accentGrey};
    font-size: ${fontSizes.small};
    text-align: center;
  `

  const router = useIonRouter();
  const { login } = useSession();
  sessionStorage.removeItem('msal.interaction.status');

  const loginProps = {
    onClick: () => handleLogin(),
    ionicStorage: Storage,
    logo: logo
  } as LoginProps;

  const handleLogin = async () => {
    login().then((response) => {
      prop.authenticationCallback();
    })
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <LoginPage>
          <Login {...loginProps} />
        </LoginPage>
        <VersionNumber>Version 1.25.0</VersionNumber>
      </IonContent>
    </IonPage>
  );
}
