import { createContext, useContext } from 'react';
import { Storage } from '@ionic/storage';

const temp = new Storage();
temp.create();
const StorageContext = createContext<Storage>(temp);

export interface StorageProps {
  children: React.ReactNode;
}

export function StorageProvider(props: StorageProps) {
  const ionicStorage = new Storage();
  ionicStorage.create();

  return (
    <StorageContext.Provider value={ionicStorage}>
      {props.children}
    </StorageContext.Provider>
  );
}

export const useStorage = () => {
  return useContext(StorageContext);
};
