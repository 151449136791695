import {CrewHubState} from "@models/src/lib/crewhub-state";
import {CrewHubAction, CrewHubActionTypes} from "../crew-hub-store/crew-hub-store-actions";
import { filterRemovedFlight, mapUpdatedFlights, mapUpdatedManifests, mapUpdatedSeatMaps } from "@utilities";

export function crewHubStateReducer(state: CrewHubState, action: CrewHubAction) {
  switch (action.type) {
    case CrewHubActionTypes.setSchedule: {
      return {...state, schedule: action.payload}
    }
    case CrewHubActionTypes.setFlights: {
      return { ...state, flights: action.payload };
    }
    case CrewHubActionTypes.updateFlight: {
      const updatedFlights = mapUpdatedFlights(state.flights, action.payload);
      return { ...state, flights: updatedFlights, flightsResponse: { ...state.flightsResponse, flights: updatedFlights } };
    }
    case CrewHubActionTypes.removeFlight: {
      const filteredFlights = filterRemovedFlight(state.flights, action.payload)
      return { ...state, flights: filteredFlights, flightsResponse: { ...state.flightsResponse, flights: filteredFlights } };
    }
    case CrewHubActionTypes.setManifests: {
      return { ...state, manifests: action.payload };
    }
    case CrewHubActionTypes.updateManifest: {
      const updatedManifests = mapUpdatedManifests(state.manifests, action.payload)
      return { ...state, manifests: updatedManifests, manifestsResponse: { ...state.manifestsResponse, manifests: updatedManifests } };
    }
    case CrewHubActionTypes.setSeatMaps: {
      return { ...state, seatMaps: action.payload };
    }
    case CrewHubActionTypes.updateSeatMap: {
      const updatedSeatMaps = mapUpdatedSeatMaps(state.seatMaps, action.payload)
      return { ...state, seatMaps: updatedSeatMaps, seatMapsResponse: { ...state.seatMapsResponse, seatMaps: updatedSeatMaps } };
    }
    case CrewHubActionTypes.setScheduleResponse: {
      return {...state, schedule: action.payload.schedule, scheduleResponse: action.payload, time: action.payload.time };
    }
    case CrewHubActionTypes.setFlightsResponse: {
      return { ...state, flights: action.payload.flights, flightsResponse: action.payload, time: action.payload.time };
    }
    case CrewHubActionTypes.setManifestsResponse: {
      return { ...state, manifests: action.payload.manifests, manifestsResponse: action.payload, time: action.payload.time };
    }
    case CrewHubActionTypes.setSeatMapsResponse: {
      return { ...state, seatMaps: action.payload.seatMaps, seatMapsResponse: action.payload, time: action.payload.time };
    }
    case CrewHubActionTypes.setTime: {
      return { ...state, time: action.payload };
    }
    case CrewHubActionTypes.setWeightBalance: {
      return { ...state, weightBalance: action.payload };
    }
    case CrewHubActionTypes.setFlightSubscriptions: {
      return { ...state, flightSubscriptions: [...state.flightSubscriptions, action.payload] };
    }
    case CrewHubActionTypes.clearFlightSubscriptions: {
      return { ...state, flightSubscriptions: [] };
    }
    case CrewHubActionTypes.setManifestSubscriptions: {
      return { ...state, manifestSubscriptions: [...state.manifestSubscriptions, action.payload] };
    }
    case CrewHubActionTypes.clearManifestSubscriptions: {
      return { ...state, manifestSubscriptions: [] };
    }
    case CrewHubActionTypes.setSeatMapSubscriptions: {
      return { ...state, seatMapSubscriptions: [...state.seatMapSubscriptions, action.payload] };
    }
    case CrewHubActionTypes.clearSeatMapSubscriptions: {
      return { ...state, seatMapSubscriptions: [] };
    }
    case CrewHubActionTypes.setLoading: {
      return {...state, loading: action.payload}
    }
    case CrewHubActionTypes.setIsOnline: {
      return {...state, isOnline: action.payload}
    }
    case CrewHubActionTypes.setState: {
      return action.payload;
    }
  }
}
