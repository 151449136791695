export enum CrewHubActionTypes {
  setSchedule,
  setFlights,
  updateFlight,
  removeFlight,
  setManifests,
  updateManifest,
  setSeatMaps,
  updateSeatMap,
  setScheduleResponse,
  setFlightsResponse,
  setManifestsResponse,
  setSeatMapsResponse,
  setTime,
  setWeightBalance,
  setFlightSubscriptions,
  clearFlightSubscriptions,
  setManifestSubscriptions,
  clearManifestSubscriptions,
  setSeatMapSubscriptions,
  clearSeatMapSubscriptions,
  setLoading,
  setIsOnline,
  setState
}

export interface CrewHubAction {
  type: CrewHubActionTypes;
  payload: any;
}
