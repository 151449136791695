import { Flight, Manifest, SeatMap } from "@models";

export const mapUpdatedFlights = (flights: Flight[], updatedFlight: Flight) => {
  return flights.map((flight: Flight) =>
    flight.uniqueId === updatedFlight.uniqueId ? updatedFlight : flight
  );
}

export const filterRemovedFlight = (flights: Flight[], removedFlight: Flight) => {
  return flights.filter((flight) =>
    flight.uniqueId !== removedFlight.uniqueId
  );
}

export const mapUpdatedManifests = (manifests: Manifest[], updatedManifest: Manifest) => {
  return manifests.map((manifest: Manifest) => {
    if (
      manifest.market?.departureDate === updatedManifest.market?.departureDate &&
      manifest.market?.origin === updatedManifest.market?.origin &&
      manifest.market?.destination === updatedManifest.market?.destination &&
      manifest.market?.identifier?.identifier === updatedManifest.market?.identifier?.identifier
    ) {
      return updatedManifest;
    } else {
      return manifest;
    }
  });
}

export const mapUpdatedSeatMaps = (seatMaps: SeatMap[], updatedSeatMap: SeatMap) => {
  return seatMaps.map((seatMap: SeatMap) => {
    if (seatMap.seatMapReference === updatedSeatMap.seatMapReference) {
      return updatedSeatMap;
    } else {
      return seatMap;
    }
  });
}
