import { clientFactory, convertTimeToLocal, extractFlightNum } from '@utilities';
import {
  DATETIME_FORMAT,
  ScheduleFlight,
  GraphResponse,
  Manifest,
  ManifestResponse,
  ManifestsResponse
} from '@models';
import { manifestQuery } from '@graphql';
import {format} from "date-fns";
const timeStamp = () => format(new Date(), DATETIME_FORMAT);

export const manifestNavitaireApi = async (
  flightSchedule: ScheduleFlight[],
  baseUrl: string,
  token?: string,
) => {
  const client = clientFactory(
    token ?? '',
    `${baseUrl}/graph/authenticated/manifest` || ''
  );

  const queries = flightSchedule.map((flight: ScheduleFlight) => {
    return client.query({
      query: manifestQuery,
      variables: {
        flightNum: extractFlightNum(flight.activityCode),
        flightDate: convertTimeToLocal(flight.start, flight.startLocalTimeDiff),
        origin: flight.startAirportCode,
        destination: flight.endAirportCode
      }
    });
  });
  const responses: GraphResponse<ManifestResponse>[] = await Promise.all(
    queries
  );
  // TODO: filtering how the handlingManifest does it but could be a better way
  // This filters out bad manifest/seatmap calls navblue/navitaire discrepancies
  const filteredResponses = responses.filter(
    (response: GraphResponse<ManifestResponse>) => {
      const market = response.data?.manifest?.manifest?.market;
      return !(
        !market?.origin ||
        !market?.destination ||
        !market?.identifier ||
        !market?.departureDate
      );
    }
  );

  const manifests: Manifest[] = filteredResponses.map(
    (response: GraphResponse<ManifestResponse>) => {
      const manifest = response.data.manifest;
      return manifest.manifest as Manifest;
    }
  );

  const manifestsResponse: ManifestsResponse = {
    manifests: manifests,
    responseCode: '200',
    errorMessage: '',
    time: timeStamp(),
  }
  return manifestsResponse;
};
